import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../reducers/reducer';

export interface InstallationPVData {
  [key: string]: string | number | object  | undefined;
  power: number;
  tailleToiture: string;
  toiture: number;
  ville: VilleType;
  orientation:number;
}
export type VilleType = {
  id: string;
  city: string;
  latitude: number;
  longitude: number;
  departement: number;
};
export interface ConsumptionData {
  [key: string]: number | string | object | undefined;
  profil: string;
  annual_conso: string;
  tarif: string;
  base: { base: number };
  hp_hc: {
    hp: number;
    hc: number;
  };
  "4cadrans": {
    hpbs: number;
    hphs: number;
    hchs: number;
    hcbs: number;
  };
  "5cadrans": {
    hpbs: number;
    hphs: number;
    hchs: number;
    hcbs: number;
    pte: number;
  };
  chart?: any
}
interface BilanEcologique{
  chart?: string [] 
}
export interface BatteryData {
    [key: string]: string | number;
    installation: string;
    pv_size: number;
    battery_size: number;
    inverter_size: number;
    annual_savings: number;
    tac: number;
    battery_cost: number;
    pv_cost: number;
  }
  export interface BilanEconomique {
    loan_rate: number;
    loan_percentage: number;
    inflation_rate: number;
    chart?: any
  }
export interface CalculatorData {
    consumption :  ConsumptionData ,
    installationPv : InstallationPVData,
    battery: Partial<BatteryData>,
    bilanEconomique:BilanEconomique,
    bilanEcologique:BilanEcologique,
}
export const DEFAULT_VILLE: VilleType = {
  id: "69271",
  city: "Chassieu",
  latitude:4.958712,
  longitude: 45.73584,
  departement: 69,
};
const initialState : CalculatorData = {
  consumption : {
    annual_conso: "30",
    profil: "thermostable",
    tarif: "base",
    hp_hc: {
      hp: 0.14,
      hc: 0.098,
    },
    base: {
      base: 0.131,
    },
    "4cadrans": {
      hcbs: 0.07,
      hchs: 0.118,
      hpbs: 0.092,
      hphs: 0.182,
    },
    "5cadrans": {
      hcbs: 0.066,
      hchs: 0.118,
      hpbs: 0.081,
      hphs: 0.158,
      pte: 0.191,
    },
  } ,
  installationPv : {
    power: 50,
    tailleToiture: (50 / 0.22).toFixed(0),
    toiture: 10,
    ville: DEFAULT_VILLE,
    orientation:0
  },
  battery: {
    installation : "smart_storage"
  },
  bilanEconomique : {
    inflation_rate: 3,
    loan_percentage: 15,
    loan_rate: 3,
  },
  bilanEcologique : {}
}
const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setConsumptionData(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['consumption']>> ) {
      state={
        ...state, consumption : {
          ...state.consumption,
          ...action.payload
        }
      }
      return state
    },
    setInstallationPVData(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['installationPv']>> ) {
      state={
        ...state, installationPv : {
          ...state.installationPv,
          ...action.payload
        }
      }
      return state
    },
    setBatteryData(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['battery']>> ) {
      state={
        ...state, battery : {
          ...state.battery,
          ...action.payload
        }
      }
      return state
    },
    setBilanEconomique(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['bilanEconomique']>> ) {
      state={
        ...state, bilanEconomique : {
          ...state.bilanEconomique,
          ...action.payload
        }
      }
      return state
    },
    setBilanEcologique(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['bilanEconomique']>> ) {
      state={
        ...state, bilanEcologique : {
          ...state.bilanEcologique,
          ...action.payload
        }
      }
      return state
    },
    
  },
})
export const { setConsumptionData,setInstallationPVData,setBatteryData,setBilanEconomique,setBilanEcologique } = calculatorSlice.actions
export const calculatorReducer = calculatorSlice.reducer
export const selectCalculatorData = (state: RootState) => state.calculator