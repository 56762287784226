import { combineReducers } from "redux";
import { calculatorReducer } from "../store/config";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { geoLocalisationApi } from "../services/geoLocalisationApi";
import { calculatorServiceApi } from "../services/calculatorService";
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from "redux-persist";


const persistConfig = {
  key: "calculator",
  storage,
};
const rootReducer = combineReducers({
  calculator: calculatorReducer,
  [geoLocalisationApi.reducerPath]: geoLocalisationApi.reducer,
  [calculatorServiceApi.reducerPath]: calculatorServiceApi.reducer,

});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
  .concat(geoLocalisationApi.middleware)
  .concat(calculatorServiceApi.middleware)
});
export const persistor = persistStore(store);
setupListeners(store.dispatch);
/*
const unsubscribe = store.subscribe(() =>
  console.log('State after dispatch: ', store.getState())
) 
*/
export type RootState = ReturnType<typeof store.getState>;
    