import { COLORS, formatter } from "../utils/toolbox";
import { DoghnutData, DoughnutGraph } from "./DoughnutGraph";
import { LabelWithValue } from "./LabelWithValue";
import React, { memo, ReactNode } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { Flex, Heading, VStack } from "@chakra-ui/react";

interface AutoConsommationIndicatorComponentProps {
  title: string | ReactNode;
  graphData: DoghnutData;
  energyDistribution: EnergyDistribution[];
  description?: string;
}
export interface EnergyDistribution {
  value: string;
  color: COLORS;
  label: string;
}
const AutoConsommationIndicatorComponent: React.FC<
  AutoConsommationIndicatorComponentProps
> = ({ title, graphData, energyDistribution, description }) => {
  return (
    <Tooltip label={description} placement={"top"}>
      <Box w={"full"} h={"full"}>
        <VStack w={"full"} h={"full"} gap={4} justifyContent={"start"}>
          <Heading
            variant={"primary"}
            textAlign={"center"}
            size={{ base: "sm", md: "md" }}
          >
            {title}
          </Heading>
          <VStack w={"full"} h={"full"} gap={4} justifyContent={"space-around"}>
            <Box
              flex={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"full"}
            >
              <DoughnutGraph
                formatter={formatter("kWh")}
                backgroundColor={graphData.backgroundColor}
                labels={graphData.labels}
                data={graphData.data}
              />
            </Box>

            <Flex wrap={"wrap"} justifyContent={"center"}>
              {energyDistribution.map((elt, index) => {
                return <LabelWithValue key={index} {...elt} />;
              })}
            </Flex>
          </VStack>
        </VStack>
      </Box>
    </Tooltip>
  );
};
export const AutoConsommationIndicator = memo(
  AutoConsommationIndicatorComponent
);
