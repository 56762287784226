import React from "react";
import { HStack, VStack, Box, Text, Icon, Tooltip } from "@chakra-ui/react";
import { COLORS } from "../utils/toolbox";
import { FaBatteryHalf, FaSolarPanel } from "react-icons/fa";

interface DynamicGraphDisplayProps {
  data: {
    co2_battery_cost?: number;
    co2_pv_cost?: number;
  };
}

const DynamicGraphDisplay: React.FC<DynamicGraphDisplayProps> = ({ data }) => {
  // On récupère les coûts en s'assurant qu'il s'agit bien de nombres
  const batteryCost = Number(data.co2_battery_cost);
  const pvCost = Number(data.co2_pv_cost);

  // Par défaut, on peut fixer une hauteur minimale si nécessaire
  let batteryHeight = 0;
  let pvHeight = 0;

  if (!isNaN(batteryCost) && !isNaN(pvCost) && batteryCost > 0 && pvCost > 0) {
    const maxCost = Math.max(batteryCost, pvCost);
    // La valeur maximale est toujours 200px
    batteryHeight =
      batteryCost === maxCost ? 200 : 200 * (batteryCost / maxCost);
    pvHeight = pvCost === maxCost ? 200 : 200 * (pvCost / maxCost);
  }

  return (
    <HStack display="flex" flexDir="row" justifyContent="space-around" w="full">
      <Tooltip label="Selon le CEA on peut estimer à 100kgCO2/kWh la production d’une batterie. Mais cette valeur reste très variable suivant le lieu de production! https://www.cea.fr/Documents/positions-cea-UE/2020-07-CEA-UE-modernisation-legislation-europeenne-batteries.pdf ">
      <VStack>
        <Text textAlign="center" fontWeight="bold">
          Battery
        </Text>
        <Box>
          <Icon
            as={FaBatteryHalf as React.ElementType}
            boxSize={`${batteryHeight}px`}
            color={COLORS.CONSO}
          />
        </Box>
        <Text textAlign="center" fontWeight="bold">
          {batteryCost}
        </Text>
      </VStack>
      </Tooltip>
      <Tooltip label="Selon le Guide du Bilan Carbone de l’ADEME et le rapport Renewable Power Generation Costs de l’IRENA, on estime entre 800 et 1000kgCO2/kWc de PV installé https://www.irena.org/Publications/2023/Aug/Renewable-Power-Generation-Costs-in-2022 ">
         <VStack>
        <Text textAlign="center" fontWeight="bold">
          PV
        </Text>
        <Box>
          <Icon
            as={FaSolarPanel as React.ElementType}
            boxSize={`${pvHeight}px`}
            color={COLORS.PROD}
          />
        </Box>
        <Text textAlign="center" fontWeight="bold">
          {pvCost}
        </Text>
      </VStack>
      </Tooltip>
     
    </HStack>
  );
};

export default DynamicGraphDisplay;
