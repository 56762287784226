/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
const BASE_URL = "https://simulator.dev-ems-lab.fr/api/v1/";

interface FullSolarSimulationRequestDto {
  departement: number;
  latitute: number;
  longitute: number;
  azimuth: number;
  angle: number;
  maxpeakpower: number;
  conso: number;
  profil_client: string;
}
interface FullSolarSimulationResponseDto {
  energy_export: number;
  energy_grid: number;
  energy_self_consumption: number;
}
interface OptiSizeRequestDto {
  departement: number;
  latitute: number;
  longitute: number;
  azimuth: number;
  angle: number;
  maxpeakpower: number;
  conso: number;
  profil_client: string;
  prices: number[];
  type: string;
}
export interface InstallSizeData {
  pv_size: number;
  battery_size: number;
  inverter_size: number;
  annual_savings: number;
  tac: number;
  battery_cost: number;
  pv_cost: number;
}
export interface OptiSizeData {
  max_autonomy: InstallSizeData;
  smart_storage: InstallSizeData;
  roi_power: InstallSizeData;
  perso: InstallSizeData;
}
interface FinancialDataRequestDto {
  simu: OptiSizeRequestDto &
    Pick<InstallSizeData, "pv_size" | "battery_size" | "inverter_size"> & {
      issuer: string;
      plotflag: boolean;
      ems_strategy: string;
    };
  inflation_rate: number;
  loan_percentage: number;
  loan_rate: number;
}
export interface CashFlowDto {
  annee: number;
  cashflow: number;
  revenus: number;
  couts: number;
  remboursement_pret: number;
}
interface FinancialDataResponseDto {
  roi: number;
  tri: number;
  tri_reel: number;
  break_even_year: number;
  lcoe: number;
  ratio_autoconsommation: number;
  gains_cumules: number;
  cashflow_annuel: CashFlowDto[];
}
export type EcoDataRequest = OptiSizeRequestDto &
  Pick<InstallSizeData, "pv_size" | "battery_size" | "inverter_size"> & {
    issuer: string;
    plotflag: boolean;
    ems_strategy: string;
  };

export interface Consumption {
  nuclear: number;
  solar: number;
  fossil: number;
  hydro: number;
  wind: number;
  other: number;
}

export interface CO2Data {
  consumption: Consumption;
  CO2: number;
}

export interface CumulativeCO2 {
  annee: number;
  co2_cumule: number;
}

export interface EcoDataResponse {
  wo_install: CO2Data;
  w_install: CO2Data;
  co2_cumul_annuel: CumulativeCO2[];
  co2_pv_cost: number;
  co2_battery_cost: number;
}
interface SendEmailResponse {
  message: string;
}
export const calculatorServiceApi = createApi({
  reducerPath: "calculatorServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareHeaders: (headers: any) => {
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    solarSimulation: builder.mutation<
      FullSolarSimulationResponseDto,
      FullSolarSimulationRequestDto
    >({
      query: (body) => ({
        url: `simulation/fullsolarsimu`,
        body,
        method: "POST",
      }),
    }),
    optisize: builder.mutation<OptiSizeData, OptiSizeRequestDto>({
      query: (body) => ({
        url: `simulation/optisize`,
        body,
        method: "POST",
      }),
    }),
    financialData: builder.mutation<
      FinancialDataResponseDto,
      FinancialDataRequestDto
    >({
      query: (body) => {
        // Construire la query string à partir des paramètres du corps
        const params = new URLSearchParams({
          inflation_rate: body.inflation_rate.toString(),
          loan_percentage: body.loan_percentage.toString(),
          loan_rate: body.loan_rate.toString(),
          // Ajouter d'autres paramètres du body si nécessaire
        });

        // Construire l'URL avec la query string
        return {
          url: `simulation/financial_data?${params.toString()}`,
          body,
          method: "POST", // ou GET selon votre cas
        };
      },
    }),
    ecoData: builder.mutation<EcoDataResponse, EcoDataRequest>({
      query: (body) => ({
        url: `simulation/eco_data`,
        body,
        method: "POST",
      }),
    }),
    senEmail: builder.mutation<SendEmailResponse, FormData>({
      query: (body) => ({
        url: `email/send-email/`,
        body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSolarSimulationMutation,
  useOptisizeMutation,
  useFinancialDataMutation,
  useEcoDataMutation,
  useSenEmailMutation,
} = calculatorServiceApi;
