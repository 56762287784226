import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

export const PrimaryInput: React.FC<InputProps> = ({ ...props }) => {
  return (
    <Input
      {...props}
      borderWidth="2px"
      borderRadius="md"
      boxShadow="0px 10px 10px rgba(0, 0, 0, 0.2)"
      textAlign="center"
      bg="content.primary.300"
      color="gray.800"
      border={"none"}
      _placeholder={{ color: "gray.800" }} 
    />
  );
};
