import React from "react";
import { Box, Icon, Tooltip, useRadio, UseRadioProps } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

// Définition des types pour les props de RadioCard
interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
  description?: string | React.ReactNode;
}

// Composant personnalisé RadioCard
export const RadioCard: React.FC<RadioCardProps> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} hidden />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="lg"
        boxShadow="0px 5px 10px rgba(0, 0, 0, 0.15)"
        textAlign="center"
        bg="content.primary.100"
        position="relative"
        color="gray.500"
        border="none"
        fontWeight="bold"
        transition="all 0.2s ease-in-out"
        outline="none"
        _checked={{
          bg: "content.primary.600",
          color: "gray.100",
          transform: "scale(1.05)", // Effet d'agrandissement au check
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.25)",
        }}
        _hover={{
          bg: "content.primary.600",
          color: "grey.100",
          transform: "scale(1.05)", // Effet d'agrandissement au check
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.25)",
        }}
        _focus={{
          boxShadow: "0px 0px 5px rgba(45, 106, 79, 0.8)",
        }}
        px={5}
        py={3}
      >
        {props.children}
        {props.description && (
          <Tooltip
            label={props.description}
            hasArrow
            placement="top"
            bg="white"
            color="gray.800"
            boxShadow="md"
            p={3}
            borderRadius="md"
          >
            <Box position="absolute" top={2} right={2}>
              <Icon
                as={InfoOutlineIcon}
                w={4}
                h={4}
                color="content.secondary.500"
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
