import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormLabel,
  Heading,
  SliderThumb,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderMark,
  Tooltip,
  GridItem,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { PrimaryButton } from "../components/PrimaryButton";
import {
  ConsumptionData,
  selectCalculatorData,
  setBilanEconomique,
} from "../store/config";
import { store } from "../reducers/reducer";
import { useFinancialDataMutation } from "../services/calculatorService";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";

export interface CashFlowDto {
  annee: number;
  cashflow: number;
  revenus: number;
  couts: number;
  remboursement_pret: number;
}
export interface BilanData {
  roi: number;
  tri: number;
  tri_reel: number;
  break_even_year: number;
  lcoe: number;
  ratio_autoconsommation: number;
  gains_cumules: number;
  cashflow_annuel: CashFlowDto[];
}
export interface BilanDataForm {
  [key: string]: number;
  loan_rate: number;
  loan_percentage: number;
  inflation_rate: number;
}
export const BilanEconomiquePage: React.FC = () => {
  const { control, handleSubmit, setValue, watch } = useForm<BilanDataForm>({
    defaultValues: {
      inflation_rate: 3,
      loan_percentage: 15,
      loan_rate: 3,
    },
  });
  const inflation_rate = watch("inflation_rate");
  const loan_percentage = watch("loan_percentage");
  const loan_rate = watch("loan_rate");

  const consumptionData = selectCalculatorData(store.getState()).consumption;
  const installationPvData = selectCalculatorData(
    store.getState()
  ).installationPv;
  const batteryData = selectCalculatorData(store.getState()).battery;
  const navigate = useNavigate();
  const dispatch = useDispatch();;
  const onSubmit = (data: BilanDataForm) => {
    // Cibler l'élément du composant à capturer
    const component = document.getElementById("chartBilanEconomique");

    // Utiliser html2canvas pour capturer le composant en tant qu'image
    if (component) {
      html2canvas(component, {
        scale: 2, // Increase the quality of the image
        logging: false, // Disable logging for faster rendering
        useCORS: true, // If you have external images or resources
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg"); // Convert canvas to image data
        dispatch(
          setBilanEconomique({
            inflation_rate: data.inflation_rate,
            loan_percentage: data.loan_percentage,
            loan_rate: data.loan_rate,
            chart: imgData,
          })
        );
        navigate("/bilanEcologique");
      });
    }
  };
  const [financialDataFn] = useFinancialDataMutation();
  const [bilanData, setBilanData] = useState<BilanData>();

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    type: "bar",
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        mode: "index",
      },
    },
  };
  const getPrices = (consumptionData: ConsumptionData) => {
    if (consumptionData.tarif == "base") {
      return [consumptionData.base.base];
    } else if (consumptionData.tarif == "hp_hc") {
      return [consumptionData.hp_hc.hp, consumptionData.hp_hc.hc];
    } else if (consumptionData.tarif == "4cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
      ];
    } else if (consumptionData.tarif == "5cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
        consumptionData["5cadrans"].pte,
      ];
    } else {
      return [];
    }
  };
  const buildCoutDataGraph = (cashflowAnnuel: CashFlowDto[]) => {
    return cashflowAnnuel.map((elt) => {
      return [elt.remboursement_pret - elt.couts, elt.remboursement_pret];
    });
  };
  const getCashflowBackgroundColor = (cashflowAnnuel: CashFlowDto[]) => {
    {
      console.log("hello");
    }
    return cashflowAnnuel
      ? cashflowAnnuel
          .map((elt, index, array) => {
            return array
              .slice(0, index + 1)
              .reduce((accum, item) => accum + item.cashflow, 0);
          })
          .map((elt) => (elt < 0 ? "#E44223" : "#1E396D"))
      : [];
  };

  const getCumulativeCashflow = (cashflowAnnuel: CashFlowDto[]) => {
    return cashflowAnnuel
      ? cashflowAnnuel
          .map((elt) => elt.cashflow)
          .map((elt) => elt / 1000)
          .map((elt, index, array) => {
            return array
              .slice(0, index + 1)
              .reduce((accum, item) => accum + item, 0);
          })
      : [];
  };
  useEffect(() => {
    const financialData = () => {
      financialDataFn({
        simu: {
          type: consumptionData.tarif.toUpperCase(),
          profil_client: consumptionData.profil,
          conso: Number(consumptionData.annual_conso) * 1000,
          angle: installationPvData.toiture,
          azimuth: installationPvData.orientation,
          departement: installationPvData.ville.departement,
          latitute: installationPvData.ville.latitude,
          longitute: installationPvData.ville.longitude,
          maxpeakpower: installationPvData.power,
          prices: getPrices(consumptionData),
          battery_size: batteryData?.battery_size || 0,
          inverter_size: batteryData?.inverter_size || 0,
          pv_size: batteryData?.pv_size || 0,
          issuer: "Form",
          plotflag: false,
          ems_strategy: "SMART",
        },
        inflation_rate,
        loan_percentage,
        loan_rate,
      })
        .unwrap()
        .then((res) => {
          setBilanData(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    financialData();
  }, [
    consumptionData,
    installationPvData,
    batteryData,
    inflation_rate,
    loan_percentage,
    loan_rate,
  ]);

  useEffect(() => {
    if (bilanData) {
      Object.keys(bilanData).forEach((key) => {
        const v = bilanData[key as keyof BilanData];
        if (typeof v == "number")
          setValue(key as keyof BilanData, Number(v.toFixed(2)));
      });
    }
  }, [setValue, bilanData]);
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
    color: "gray.800",
    fontWeight: "bold",
  };
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <Box p={4} flex={1} h={"full"}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "100%",
          gap: 12,
        }}
      >
        <Grid
          templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          templateRows={"auto"}
          gap={4}
          py={2}
        >
          {["loan_rate", "inflation_rate", "loan_percentage"].map((field) => (
            <React.Fragment key={field}>
              <FormLabel w={"full"}>
                <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                  {field === "loan_rate"
                    ? "Taux d'emprunts"
                    : field === "loan_percentage"
                    ? "Ratio d'emprunts"
                    : field === "inflation_rate"
                    ? "Taux d'inflation"
                    : ""}
                </Heading>
              </FormLabel>
              <Box>
                {field === "loan_percentage" ? (
                  <Controller
                    name={field}
                    control={control}
                    render={({ field: controllerField }) => (
                      <Slider
                        {...controllerField}
                        aria-label="Loan_rate slider"
                        min={0}
                        max={100}
                        step={5}
                        value={controllerField.value}
                        onChange={(value) => {
                          controllerField.onChange(value);
                        }}
                        borderWidth="2px"
                        borderRadius="md"
                        boxShadow="0px 10px 10px rgba(0, 0, 0, 0.2)"
                        textAlign="center"
                        bg="content.primary.300"
                        color="gray.800"
                        border={"none"}
                        _placeholder={{ color: "gray.800" }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <SliderMark value={25} {...labelStyles}>
                          25%
                        </SliderMark>
                        <SliderMark value={50} {...labelStyles}>
                          50%
                        </SliderMark>
                        <SliderMark value={75} {...labelStyles}>
                          75%
                        </SliderMark>

                        <SliderTrack bg="content.primary.300">
                          <SliderFilledTrack bg="content.primary.500" />
                        </SliderTrack>
                        <Tooltip
                          hasArrow
                          bg="content.primary.500"
                          color="white"
                          placement="top"
                          isOpen={showTooltip}
                          label={`${controllerField.value}%`}
                        >
                          <SliderThumb
                            boxSize={6}
                            bg="content.primary.500"
                            borderRadius="full"
                            boxShadow="md"
                          />
                        </Tooltip>
                      </Slider>
                    )}
                  />
                ) : (
                  <Controller
                    name={field}
                    control={control}
                    render={({ field: controllerField }) => (
                      <InputGroup width="150px">
                        <PrimaryInput
                          type="number"
                          width="150px"
                          {...controllerField}
                          min={0}
                          max={100}
                          value={controllerField.value}
                          onChange={(e) => {
                            if (
                              Number(e.target.value) <= 100 &&
                              Number(e.target.value) >= 0
                            )
                              controllerField.onChange(Number(e.target.value));
                          }}
                        />
                        <InputRightElement>
                          <Text fontWeight={"bold"}>%</Text>
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                )}
              </Box>
            </React.Fragment>
          ))}
        </Grid>
        <Box id="chartBilanEconomique">
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={{ base: 4, md: 10 }}
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            py={10}
            h={"400px"}
          >
            <GridItem
              w="full"
              rowSpan={{ base: 1, md: 2 }}
              colSpan={{
                base: 2,
                md: 1,
              }}
              h={"full"}
              display={"flex"}
              flexDir={"column"}
              gap={4}
            >
              <Heading textAlign={"center"}>Cumul de cashflow (k€)</Heading>
              <Box h={"full"}>
                <Bar
                  data={{
                    labels: bilanData?.cashflow_annuel.map((elt) => elt.annee),
                    datasets: [
                      {
                        label: "Cumul de cashflow",
                        backgroundColor: getCashflowBackgroundColor(
                          bilanData?.cashflow_annuel ?? []
                        ),
                        data: getCumulativeCashflow(
                          bilanData?.cashflow_annuel ?? []
                        ),
                      },
                    ],
                  }}
                  options={{
                    ...options,
                    scales: {
                      ...options.scales,
                      y: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Trésorerie (K€)", // This is the label for the Y-axis
                        },
                      },
                      x: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Années", // This is the label for the Y-axis
                        },
                      },
                    },
                  }}
                />
              </Box>
            </GridItem>
            <GridItem
              w="full"
              rowSpan={{ base: 1, md: 2 }}
              colSpan={{
                base: 2,
                md: 1,
              }}
              h={"full"}
              display={"flex"}
              flexDir={"column"}
              gap={4}
            >
              <Heading textAlign={"center"}>Remboursement pret + coûts</Heading>
              <Box h={"full"}>
                <Bar
                  data={{
                    labels: bilanData?.cashflow_annuel.map((elt) => elt.annee),
                    datasets: [
                      {
                        label: "Reduction Facture et Surplus",
                        backgroundColor: bilanData?.cashflow_annuel
                          .map((elt) => elt.revenus)
                          .map(() => "#9C9583"),
                        data: bilanData
                          ? bilanData?.cashflow_annuel
                              .map((elt) => elt.revenus)
                              .map((elt) => [0, elt])
                          : [],
                      },
                      {
                        label: "Remboursement pret",
                        backgroundColor: bilanData?.cashflow_annuel
                          .map((elt) => elt.remboursement_pret)
                          .map(() => "#E44223"),
                        data: bilanData
                          ? bilanData?.cashflow_annuel
                              .map((elt) => elt.remboursement_pret)
                              .map((elt) => [0, elt])
                          : [],
                      },
                      {
                        label: "Cout",
                        backgroundColor: bilanData?.cashflow_annuel
                          .map((elt) => elt.couts)
                          .map(() => "#9C9583"),
                        data: buildCoutDataGraph(
                          bilanData?.cashflow_annuel ?? []
                        ),
                        base: buildCoutDataGraph(
                          bilanData?.cashflow_annuel ?? []
                        ).map((item) => item[0]),
                      },
                    ],
                  }}
                  options={{
                    ...options,
                    scales: {
                      ...options.scales,
                      y: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Bilan annuel (K€)", // This is the label for the Y-axis
                        },
                      },
                      x: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Années", // This is the label for the Y-axis
                        },
                      },
                    },
                  }}
                />
              </Box>
            </GridItem>
          </Grid>
        </Box>
        <Box>
          <Grid
            templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
            templateRows={"auto"}
            gap={4}
            py={2}
          >
            {[
              "roi",
              "gains_cumules",
              "tri",
              "tri_reel",
              "lcoe",
              "ratio_autoconsommation",
              "break_even_year",
            ].map((field) => (
              <React.Fragment key={field}>
                <FormLabel w={"full"}>
                  <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                    {field === "roi"
                      ? "ROI (Return on investement)"
                      : field === "gains_cumules"
                      ? "Gains cumulés (€)"
                      : field == "tri"
                      ? "TRI (Taux de Rentabilité Interne)"
                      : field == "tri_reel"
                      ? "Tri réel"
                      : field == "break_even_year"
                      ? "Année de rentabilité"
                      : field == "lcoe"
                      ? "Cout moyen de l'éléctricité produite (LCOE)"
                      : field == "ratio_autoconsommation"
                      ? "Ratio d'autoconsommation"
                      : ""}
                  </Heading>
                </FormLabel>
                <Box>
                  <Controller
                    name={field}
                    control={control}
                    render={({ field: controllerField }) => (
                      <PrimaryInput
                        type="number"
                        width="150px"
                        isDisabled
                        {...controllerField}
                        value={controllerField.value}
                        onChange={(e) =>
                          controllerField.onChange(Number(e.target.value))
                        }
                      />
                    )}
                  />
                </Box>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
