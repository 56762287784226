import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormLabel,
  Grid,
  Heading,
  InputGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { PrimaryButton } from "../components/PrimaryButton";
import { ConsumptionData, selectCalculatorData } from "../store/config";
import { store } from "../reducers/reducer";
import { COLORS } from "../utils/toolbox";
import backgroundImage from "../assets/img/background/fond.png";

import {
  useFinancialDataMutation, useSenEmailMutation
} from "../services/calculatorService";
import { BilanData } from "./BilanEconomiquePage";
import { Controller, useForm } from "react-hook-form";
import { PrimarySelect } from "../components/PrimarySelect";
import { PrimaryCheckBox } from "../components/PrimaryCheckBox";
import { PrimaryInput } from "../components/PrimaryInput";

interface ImageDataType {
  data: string;
  title: string;
  height?: number;
}

interface UserData {
  [key: string]: string | boolean;
  nom: string;
  prenom: string;
  adressemail: string;
  civilite: string;
  entreprise: string;
  consent: boolean;
}
export const RapportPage: React.FC = () => {
  const consumptionData = selectCalculatorData(store.getState()).consumption;
  const installationPvData = selectCalculatorData(
    store.getState()
  ).installationPv;
  const batteryData = selectCalculatorData(store.getState()).battery;
  const bilanEconomique = selectCalculatorData(
    store.getState()
  ).bilanEconomique;
  const bilanEcologique = selectCalculatorData(
    store.getState()
  ).bilanEcologique;

  const [financialDataFn] = useFinancialDataMutation();

  const [bilanData, setBilanData] = useState<BilanData | null>(null);

  const getPrices = (data: ConsumptionData) => {
    if (data.tarif === "base") return [data.base.base];
    if (data.tarif === "hp_hc") return [data.hp_hc.hp, data.hp_hc.hc];
    if (data.tarif === "4cadrans") {
      return [
        data["4cadrans"].hphs,
        data["4cadrans"].hchs,
        data["4cadrans"].hpbs,
        data["4cadrans"].hcbs,
      ];
    }
    if (data.tarif === "5cadrans") {
      return [
        data["4cadrans"].hphs,
        data["4cadrans"].hchs,
        data["4cadrans"].hpbs,
        data["4cadrans"].hcbs,
        data["5cadrans"].pte,
      ];
    }
    return [];
  };

  const fetchBilanEconomique = useCallback(() => {
    if (!bilanEconomique) return;
    financialDataFn({
      simu: {
        type: consumptionData.tarif.toUpperCase(),
        profil_client: consumptionData.profil,
        conso: Number(consumptionData.annual_conso) * 1000,
        angle: installationPvData.toiture,
        azimuth: installationPvData.orientation,
        departement: installationPvData.ville.departement,
        latitute: installationPvData.ville.latitude,
        longitute: installationPvData.ville.longitude,
        maxpeakpower: installationPvData.power,
        prices: getPrices(consumptionData),
        battery_size: batteryData?.battery_size || 0,
        inverter_size: batteryData?.inverter_size || 0,
        pv_size: batteryData?.pv_size || 0,
        issuer: "Form",
        plotflag: false,
        ems_strategy: "SMART",
      },
      inflation_rate: bilanEconomique.inflation_rate,
      loan_percentage: bilanEconomique.loan_percentage,
      loan_rate: bilanEconomique.loan_rate,
    })
      .unwrap()
      .then((res) => {
        setBilanData(res);
      })
      .catch(console.error);
  }, [
    bilanEconomique,
    batteryData,
    consumptionData,
    financialDataFn,
    installationPvData,
  ]);

  useEffect(() => {
    fetchBilanEconomique();
  }, [fetchBilanEconomique]);

  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
      compress: true,
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Chargement de l'image de fond
    const background = new Image();
    background.src = backgroundImage;

    // Page de garde
    pdf.setFillColor(COLORS.PROD);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");
    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(36);
    pdf.text("Rapport NOÉ", pdfWidth / 2, pdfHeight / 2 - 20, {
      align: "center",
    });

    const addStyledPage = () => {
      pdf.addPage();
      pdf.addImage(background, "PNG", 0, 0, pdfWidth, pdfHeight); // fond
      pdf.setTextColor(0, 0, 0);
    };

    const addTableSlide = (title: string, tableData: any[][]) => {
      addStyledPage();
      pdf.setFontSize(24);
      pdf.text(title, pdfWidth / 2, 20, { align: "center" });

      autoTable(pdf, {
        head: [["Description", "Valeur"]],
        body: tableData,
        startY: 40,
        theme: "grid",
        styles: {
          font: "helvetica",
          halign: "center",
          cellPadding: 3,
          fillColor: [240, 240, 240],
        },
        headStyles: { fillColor: COLORS.PROD },
      });
    };

    addTableSlide("Simulation", [
      ["Profil", consumptionData.profil],
      ["Consommation (kWh)", consumptionData.annual_conso],
      ["Tarif", consumptionData.tarif],
      [
        "Tarif détaillé",
        JSON.stringify(consumptionData[consumptionData.tarif]),
      ],
      ["Ville", installationPvData.ville.city],
      ["Surface toiture (m²)", installationPvData.tailleToiture],
      ["Puissance (kWc)", installationPvData.power],
      ["Orientation", installationPvData.orientation],
      ["Inclinaison", installationPvData.toiture],
    ]);

    addTableSlide("Bilan Économique", [
      ["ROI", bilanData?.roi?.toFixed(2) || "0"],
      ["TRI", bilanData?.tri?.toFixed(2) || "0"],
      ["TRI Réel", bilanData?.tri_reel?.toFixed(2) || "0"],
      ["Break Even Year", bilanData?.break_even_year || "0"],
      ["LCOE", bilanData?.lcoe?.toFixed(2) || "0"],
      [
        "Ratio Autoconsommation",
        bilanData?.ratio_autoconsommation?.toFixed(2) || "0",
      ],
      ["Gains Cumulés", bilanData?.gains_cumules?.toFixed(2) || "0"],
    ]);

    const images: ImageDataType[] = [];

    if(consumptionData)
    images.push({ title: `Consommation`, data: consumptionData.chart, height: 80 });


    if (bilanEconomique && Array.isArray(bilanEconomique.chart)) {
      images.push({
        title: "Trésorerie",
        data: bilanEconomique.chart[0],
        height: 115,
      });
      images.push({
        title: "Dépenses & Revenus",
        data: bilanEconomique.chart[1],
        height: 115,
      });
    }

    if (bilanEcologique && Array.isArray(bilanEcologique.chart)) {
      images.push({
        title: "Répartition de la Consommation",
        data: bilanEcologique.chart[0],
        height: 80,
      });
      images.push({
        title: "Bilan Écologique",
        data: bilanEcologique.chart[1],
        height: 80,
      });
    }

    for (const img of images) {
      addStyledPage();
      pdf.setFontSize(24);
      pdf.text(img.title, pdfWidth / 2, 20, { align: "center" });

      const imgWidth = pdfWidth - 40;
      const imgHeight = img.height || 80;
      pdf.addImage(img.data, "JPEG", 20, 60, imgWidth, imgHeight);
    }
    // Sauvegarder le PDF généré
    return pdf.output("blob");
  };

  const [sendEmail, { isLoading: sendEmailIsLoading }] = useSenEmailMutation();

  const onSubmit = (user: UserData) => {
    const pdfBlob = generatePDF();
    const formData = new FormData();
    formData.append("file", pdfBlob, "Rapport.pdf");
    formData.append("user", JSON.stringify(user));
    sendEmail(formData)
      .unwrap()
      .then((res) => {
        toast({
          title: "",
          description: res.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          description: "Une erreur est survenue !",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  const { control: userControl, handleSubmit } = useForm<UserData>({
    defaultValues: {
      civilite: "Mr",
    },
  });
  const toast = useToast();
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: "100%",
        gap: 12,
      }}
    >
      <Grid
        templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
        templateRows={"auto"}
        gap={4}
        py={2}
        p={4}
      >
        {[
          "nom",
          "prenom",
          "adressemail",
          "civilite",
          "consent",
          "entreprise",
        ].map((field) => (
          <React.Fragment key={field}>
            <FormLabel w={"full"} display={"flex"} flexDir={"row"}>
              <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                {field === "prenom"
                  ? "Prénom"
                  : field === "nom"
                  ? "Nom"
                  : field === "adressemail"
                  ? "Email"
                  : field == "civilite"
                  ? "Civilité"
                  : field == "entreprise"
                  ? "Entreprise"
                  : field == "consent"
                  ? "J'accepte que mes données soient enregistrées"
                  : ""}
              </Heading>
              {/* Ajouter l'étoile rouge pour les champs obligatoires */}
              {["nom", "prenom", "adressemail", "entreprise"].includes(
                field
              ) && (
                <Text as="span" color="red.500" ml={1}>
                  *
                </Text>
              )}
            </FormLabel>
            <Box>
              <Controller
                name={field}
                control={userControl}
                render={({ field: controllerField }) => (
                  <InputGroup maxW="300px">
                    {field == "civilite" ? (
                      <PrimarySelect
                        name={field}
                        value={controllerField.value as string}
                        onChange={(e) => {
                          controllerField.onChange(e.target.value);
                        }}
                        width="200px"
                        options={[
                          {
                            key: "0",
                            value: "Mr",
                            viewValue: "Monsieur",
                          },
                          {
                            key: "1",
                            value: "Mme",
                            viewValue: "Madame",
                          },
                        ]}
                      />
                    ) : field == "consent" ? (
                      <PrimaryCheckBox
                        {...controllerField}
                        value={controllerField.value as string}
                        onChange={(e) => {
                          controllerField.onChange(e.target.checked);
                        }}
                      />
                    ) : (
                      <PrimaryInput
                        isRequired={[
                          "nom",
                          "prenom",
                          "adressemail",
                          "entreprise",
                        ].includes(field)}
                        type={"text"}
                        {...controllerField}
                        value={controllerField.value as string}
                        onChange={(e) => {
                          controllerField.onChange(e.target.value);
                        }}
                      />
                    )}
                  </InputGroup>
                )}
              />
            </Box>
          </React.Fragment>
        ))}
      </Grid>

      <Box display="flex" justifyContent="end" m={2}>
        <PrimaryButton
          type="submit"
          px={{ base: 8, md: 16 }}
          py={{ base: 4, md: 8 }}
          isLoading={sendEmailIsLoading}
        >
          Envoyer
        </PrimaryButton>
      </Box>
    </form>
  );
};

export default RapportPage;
