import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

interface FormLabelInputProps extends FormLabelProps {
  variant?: "tarif" | "other"; // Permet de définir un style pour H1 ou H2
}

export const FormLabelInput: React.FC<FormLabelInputProps> = ({
  variant = "tarif",
  children,
  ...props
}) => {
  // Définition des styles en fonction du variant
  const size =
    variant === "tarif"
      ? { base: "100px", md: "100px", lg: "100px" }
      : { base: "sm", md: "md", lg: "lg" };
  const color = variant === "tarif" ? "gray.900" : "gray.500";
  const fontWeight = "bold";

  return (
    <FormLabel
      width={size}
      color={color}
      fontWeight={fontWeight}
      textAlign="left"
      {...props}
    >
      {children}
    </FormLabel>
  );
};
