// src/pages/WelcomePage.tsx
import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/PrimaryButton";

// Remplacez ce chemin par le vôtre si vous avez un logo NOÉ
import noeLogo from "../assets/img/logos/noe.svg";

export const WelcomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      w="full"
      //minH="calc(100vh - 80px)" // Ajustez selon la hauteur de votre header
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={4}
      py={8}
      textAlign="center"
      //bg="content.primary.100" // Exemple : fond clair issu de votre thème
    >
      {/* Logo NOÉ (optionnel, selon vos assets) */}
      <Image
        src={noeLogo}
        alt="Logo NOÉ"
        boxSize={{ base: "150px", md: "200px", lg: "300px" }}
        mb={6}
      />

      <Heading
        as="h1"
        size={{ base: "md", md: "lg" }}
        color="nav.primary.500" // Exemple : couleur de titre depuis votre thème
        mb={4}
      >
        Bienvenue sur le simulateur NOÉ
      </Heading>

      <Text fontSize={{ base: "sm", md: "md" }} mb={6} maxW="600px">
        La <strong>Nouvelle Optimisation Énergétique</strong> combine
        l’intégration d’un système Photovoltaïque, d’une batterie et d’un
        pilotage intelligent pour maximiser votre autoconsommation. NOÉ s’adapte
        automatiquement à votre tarification et à vos besoins en énergie.
      </Text>

      <Text fontSize={{ base: "sm", md: "md" }} maxW="800px" mb={8}>
        En quelques étapes, vous serez invité à renseigner les informations de
        votre consommation actuelle et de votre potentiel photovoltaïque. Vous
        découvrirez ensuite le dimensionnement optimal de votre installation.
        Enfin, vous aurez la possibilité d’exporter vos résultats et, si vous le
        souhaitez, de laisser vos coordonnées pour être recontacté.
      </Text>

      <PrimaryButton
        onClick={() => navigate("/Consommation")}
        px={{ base: 6, md: 12 }}
        py={{ base: 3, md: 6 }}
      >
        C’est parti !
      </PrimaryButton>
    </Box>
  );
};
