import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { DoghnutData } from "../components/DoughnutGraph";
import { COLORS, getNumberFormatter } from "../utils/toolbox";
import { FaBatteryHalf, FaSolarPanel } from "react-icons/fa";
import {
  AutoConsommationIndicator,
  EnergyDistribution,
} from "../components/AutoConsommationIndicatorComponent";
import { PrimaryButton } from "../components/PrimaryButton";
import {
  ConsumptionData,
  selectCalculatorData,
  setBilanEcologique,
} from "../store/config";
import { store } from "../reducers/reducer";
import {
  CO2Data,
  EcoDataResponse,
  useEcoDataMutation,
} from "../services/calculatorService";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import DynamicGraphDisplay from "../components/DynamicGraphDisplay";

export interface AutoConsommationCardProps {
  graphData: DoghnutData;
  title: string | ReactNode;
  energyDistribution: EnergyDistribution[];
  description?: string;
}

export const AutoConsommationCard: React.FC<AutoConsommationCardProps> = ({
  graphData,
  title,
  energyDistribution,
  description,
}) => {
  return (
    <Box h="400px">
      <AutoConsommationIndicator
        graphData={graphData}
        title={title}
        energyDistribution={energyDistribution}
        description={description}
      />
    </Box>
  );
};

export const BilanEcologiquePage: React.FC = () => {
  const labels: string[] = ["Solaire", "Nucléaire", "Fossile", "Autre"];
  const [data, setData] = useState<EcoDataResponse>();
  // Helper function to get consumption data
  const getConsumptionData = (installData: CO2Data) => [
    installData.consumption.solar,
    installData.consumption.nuclear,
    installData.consumption.fossil,
    installData.consumption.hydro,
    installData.consumption.wind,
    installData.consumption.other,
  ];

  // Define a color map for each type of energy
  const energyColors = [
    COLORS.SOLAR,
    COLORS.NUCLEAR,
    COLORS.FOSSIL,
    COLORS.HYDRO,
    COLORS.WIND,
    COLORS.OTHER,
  ];

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    type: "bar",
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        mode: "index",
      },
    },
  };
  // Define donut data for WI (with installation)
  const doghnutDataWI: DoghnutData = {
    backgroundColor: data ? energyColors : [],
    labels: labels,
    data: data ? getConsumptionData(data.w_install) : [],
  };

  // Define donut data for WO (without installation)
  const doghnutDataWO: DoghnutData = {
    backgroundColor: data ? energyColors : [],
    labels: labels,
    data: data ? getConsumptionData(data.wo_install) : [],
  };
  const energyDistributionWI: EnergyDistribution[] = [
    {
      value: data?.w_install.consumption.solar
        ? getNumberFormatter().format(
            data?.w_install.consumption.solar * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.SOLAR,
      label: "Solar",
    },
    {
      value: data?.w_install.consumption.nuclear
        ? getNumberFormatter().format(
            data?.w_install.consumption.nuclear * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.NUCLEAR,
      label: "Nuclear",
    },
    {
      value: data?.w_install.consumption.fossil
        ? getNumberFormatter().format(
            data?.w_install.consumption.fossil * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.FOSSIL,
      label: "Fossil",
    },
    {
      value: data?.w_install.consumption.hydro
        ? getNumberFormatter().format(
            data?.w_install.consumption.hydro * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.HYDRO,
      label: "Hydro",
    },
    {
      value: data?.w_install.consumption.wind
        ? getNumberFormatter().format(data?.w_install.consumption.wind * 1000) +
          "Wh"
        : "0 kWh",
      color: COLORS.WIND,
      label: "Wind",
    },
    {
      value: data?.w_install.consumption.other
        ? getNumberFormatter().format(
            data?.w_install.consumption.other * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.OTHER,
      label: "Other",
    },
  ];

  const energyDistributionWO: EnergyDistribution[] = [
    {
      value: data?.wo_install.consumption.solar
        ? getNumberFormatter().format(
            data?.wo_install.consumption.solar * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.SOLAR,
      label: "Solar",
    },
    {
      value: data?.wo_install.consumption.nuclear
        ? getNumberFormatter().format(
            data?.wo_install.consumption.nuclear * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.NUCLEAR,
      label: "Nuclear",
    },
    {
      value: data?.wo_install.consumption.fossil
        ? getNumberFormatter().format(
            data?.wo_install.consumption.fossil * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.FOSSIL,
      label: "Fossil",
    },
    {
      value: data?.wo_install.consumption.hydro
        ? getNumberFormatter().format(
            data?.wo_install.consumption.hydro * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.HYDRO,
      label: "Hydro",
    },
    {
      value: data?.wo_install.consumption.wind
        ? getNumberFormatter().format(
            data?.wo_install.consumption.wind * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.WIND,
      label: "Wind",
    },
    {
      value: data?.wo_install.consumption.other
        ? getNumberFormatter().format(
            data?.wo_install.consumption.other * 1000
          ) + "Wh"
        : "0 kWh",
      color: COLORS.OTHER,
      label: "Other",
    },
  ];

  const getPrices = (consumptionData: ConsumptionData) => {
    if (consumptionData.tarif == "base") {
      return [consumptionData.base.base];
    } else if (consumptionData.tarif == "hp_hc") {
      return [consumptionData.hp_hc.hp, consumptionData.hp_hc.hc];
    } else if (consumptionData.tarif == "4cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
      ];
    } else if (consumptionData.tarif == "5cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
        consumptionData["5cadrans"].pte,
      ];
    } else {
      return [];
    }
  };

  const consumptionData = selectCalculatorData(store.getState()).consumption;
  const installationPvData = selectCalculatorData(
    store.getState()
  ).installationPv;
  const batteryData = selectCalculatorData(store.getState()).battery;

  const [ecoData, { isError, isLoading }] = useEcoDataMutation();
  const runSimulation = useCallback(() => {
    const simulation = async () => {
      return await ecoData({
        type: consumptionData.tarif.toUpperCase(),
        profil_client: consumptionData.profil,
        conso: Number(consumptionData.annual_conso) * 1000,
        angle: installationPvData.toiture,
        azimuth: installationPvData.orientation,
        departement: installationPvData.ville.departement,
        latitute: installationPvData.ville.latitude,
        longitute: installationPvData.ville.longitude,
        maxpeakpower: installationPvData.power,
        prices: getPrices(consumptionData),
        battery_size: batteryData?.battery_size || 0,
        inverter_size: batteryData?.inverter_size || 0,
        pv_size: batteryData?.pv_size || 0,
        issuer: "Form",
        plotflag: false,
        ems_strategy: "SMART",
      }).unwrap();
    };
    try {
      const response = simulation();
      response
        .then((ecoData) => {
          setData(ecoData);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [consumptionData, installationPvData]);

  useEffect(() => {
    runSimulation();
  }, [runSimulation]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return isError ? (
    <HStack
      alignItems={"center"}
      display={"flex"}
      alignContent={"center"}
      pt={15}
      justifyContent={"center"}
    >
      <Heading
        variant={"primary"}
        textAlign={"center"}
        size={{ base: "sm", md: "md" }}
        color={"red"}
      >
        Une erreur est survenue !
      </Heading>
      <Button variant={"outline"} onClick={runSimulation}>
        Réessayer
      </Button>
    </HStack>
  ) : (
    <Box
      w={"full"}
      maxH={"fit-content"}
      p={2}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: "100%",
        gap: 12,
      }}
      overflow={"auto"}
    >
      <Box py={4}>
        <Box id="bilanEcologique1">
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={{ base: 4, md: 20 }}
            display={"flex"}
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            py={10}
            maxH={"fit-content"}
          >
            <Skeleton isLoaded={!isLoading}>
              <AutoConsommationCard
                description="Etablie la répartition de consommation du site sur le réseau électrique français et avec l’installation PV + Batterie choisie. Les énergies sont calculées heures par heures à l’aide des données fournies par RTE https://www.rte-france.com/eco2mix/la-production-delectricite-par-filiere"
                graphData={doghnutDataWI}
                title={
                  <VStack>
                    <Text>Avec installation</Text>
                    <Text fontSize={"sm"}>
                      Co2 annuel :{" "}
                      {data?.w_install.CO2 &&
                        getNumberFormatter().format(data?.w_install.CO2)}
                    </Text>
                  </VStack>
                }
                energyDistribution={energyDistributionWI}
              />
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <AutoConsommationCard
                description="Etablie la répartition de consommation sur le réseau électrique français du site. Les énergies sont calculées heures par heures à l’aide des données fournies par RTE https://www.rte-france.com/eco2mix/la-production-delectricite-par-filiere"
                graphData={doghnutDataWO}
                title={
                  <VStack>
                    <Text>Sans installation</Text>
                    <Text fontSize={"sm"}>
                      Co2 annuel :{" "}
                      {data?.w_install.CO2 &&
                        getNumberFormatter().format(data?.wo_install.CO2)}
                    </Text>
                  </VStack>
                }
                energyDistribution={energyDistributionWO}
              />
            </Skeleton>
          </Grid>
        </Box>
        <Box id="bilanEcologique2">
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={{ base: 4, md: 10 }}
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            h={"400px"}
          >
            <GridItem
              w="full"
              rowSpan={{ base: 1, md: 2 }}
              colSpan={{
                base: 2,
                md: 1,
              }}
              h={"full"}
              display={"flex"}
              flexDir={"column"}
              gap={4}
            >
              <Heading textAlign={"center"}>Cumul CO2 (kgEq)</Heading>
              <Tooltip>
<Box h={"full"}>
                <Bar
                  data={{
                    labels: data?.co2_cumul_annuel.map((elt) => elt.annee),
                    datasets: [
                      {
                        label: "Cumul CO2 (kgEq)",
                        backgroundColor: "#808080",
                        data: data?.co2_cumul_annuel.map(
                          (elt) => elt.co2_cumule
                        ),
                      },
                    ],
                  }}
                  options={{
                    ...options,
                    scales: {
                      ...options.scales,
                      y: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Cumul de CO2", // This is the label for the Y-axis
                        },
                      },
                      x: {
                        beginAtZero: true, // optional, to start y-axis at 0
                        title: {
                          display: true,
                          text: "Années", // This is the label for the Y-axis
                        },
                      },
                    },
                  }}
                />
              </Box>
              </Tooltip>
              
            </GridItem>
            <GridItem
              w="full"
              rowSpan={{ base: 1, md: 2 }}
              colSpan={{
                base: 2,
                md: 1,
              }}
              h={"full"}
              display={"flex"}
              flexDir={"column"}
              alignContent={"center"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={4}
            >
              <Heading textAlign={"center"}>Cout CO2 (kgEq)</Heading>
              {data && <DynamicGraphDisplay data={data} />}
            </GridItem>
          </Grid>
        </Box>
      </Box>

      <Box display="flex" justifyContent="end" m={2}>
        <PrimaryButton
          onClick={() => {
            // Cibler l'élément du composant à capturer
            const bilanEcologique1 =
              document.getElementById("bilanEcologique1");
            const bilanEcologique2 =
              document.getElementById("bilanEcologique2");

            // Utiliser html2canvas pour capturer le composant en tant qu'image
            if (bilanEcologique1) {
              html2canvas(bilanEcologique1, {
                scale: 2, // Increase the quality of the image
                logging: false, // Disable logging for faster rendering
                useCORS: true, // If you have external images or resources
              }).then((canvas) => {
                const bilan: string[] = [];
                const bilanEcologique1Image = canvas.toDataURL("image/jpeg"); // Convert canvas to image data
                bilan.push(bilanEcologique1Image);
                if (bilanEcologique2) {
                  html2canvas(bilanEcologique2, {
                    scale: 2, // Increase the quality of the image
                    logging: false, // Disable logging for faster rendering
                    useCORS: true, // If you have external images or resources
                  }).then((canvas) => {
                    const bilanEcologique2Image =
                      canvas.toDataURL("image/jpeg"); // Convert canvas to image data
                    bilan.push(bilanEcologique2Image);

                    dispatch(
                      setBilanEcologique({
                        chart: bilan,
                      })
                    );
                    navigate("/rapport");
                  });
                }
              });
            }
          }}
          px={{ base: 8, md: 16 }}
          py={{ base: 4, md: 8 }}
        >
          Suivant
        </PrimaryButton>
      </Box>
    </Box>
  );
};
