import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { Provider } from "react-redux";
import { persistor, store } from "./reducers/reducer";
import { ThemeProvider } from "./contexts/ThemeContext";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Router>
            <AppRoutes />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
