import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormLabel,
  Text,
  VStack,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import { Control, Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { PrimaryButton } from "../components/PrimaryButton";
import {
  Feature,
  useLazySearchLocalisationQuery,
} from "../services/geoLocalisationApi";
import { getLoadingDataErrorByStatus } from "../utils/toolbox";
import EmsAlert from "../components/EmsAlert";
import { PrimarySelect } from "../components/PrimarySelect";
import { PrimaryHeading } from "../components/PrimaryHeading";
import { RoofPowerCalculator } from "../components/RoofPowerCalculator";
import { RadioGroupField } from "../components/RadioGroupField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Image } from "@chakra-ui/react";

import {
  DEFAULT_VILLE,
  InstallationPVData,
  selectCalculatorData,
  setInstallationPVData,
  VilleType,
} from "../store/config";
import { store } from "../reducers/reducer";

interface Option {
  value: number;
  viewValue: string;
  description?: string | React.ReactNode;
}

const ORIENTATION_OPTIONS: Option[] = [
  { value: 180, viewValue: "Nord" },
  { value: 0, viewValue: "Sud" },
  { value: -90, viewValue: "Est" },
  { value: 90, viewValue: "Ouest" },
];

const ANGLE_OPTIONS: Option[] = [
  {
    value: 0,
    viewValue: "À plat (0°)",
    description: (
      <VStack spacing={2} align="start">
        <Text fontWeight="bold">Panneaux à plat</Text>
        <Image src="/dynamic_assets/img/angle-0.png" alt="À plat" w="150px" />
        <Text fontSize="sm">Utilisé pour les toits plats ou au sol.</Text>
      </VStack>
    ),
  },
  {
    value: 10,
    viewValue: "Faible (5/10°)",
    description: (
      <VStack spacing={2} align="start">
        <Text fontWeight="bold">Inclinaison faible</Text>
        <Image src="/dynamic_assets/img/angle-10.png" alt="Faible" w="150px" />
        <Text fontSize="sm">Installation de toiture avec support.</Text>
      </VStack>
    ),
  },
  /*   {
    value: 20,
    viewValue: "Standard (~20°)",
    description: (
      <VStack spacing={2} align="start">
        <Text fontWeight="bold">Inclinaison standard</Text>
        <Image
          src="/dynamic_assets/img/angle-20.png"
          alt="Standard"
          w="150px"
        />
        <Text fontSize="sm">Sur .</Text>
      </VStack>
    ),
  }, */
  {
    value: 30,
    viewValue: "Forte (>30°)",
    description: (
      <VStack spacing={2} align="start">
        <Text fontWeight="bold">Montage en façade</Text>
        <Image src="/dynamic_assets/img/angle-30.png" alt="Façade" w="150px" />
        <Text fontSize="sm">
          Utilisé sur des toits de batiments agricole et hangar.
        </Text>
      </VStack>
    ),
  },
];

type VilleDropdownProps = {
  control: Control<InstallationPVData>;
  error?: { status?: number };
  setValue: (name: string, value: any) => void;
  defaultVille: VilleType;
};
const VilleDropdown: React.FC<VilleDropdownProps> = ({
  control,
  defaultVille,
}) => {
  const [text, setText] = useState("");
  const [features, setFeatures] = useState<Feature[]>([]);
  const [searchLocation, { error }] = useLazySearchLocalisationQuery();
  useEffect(() => {
    if (text.length >= 3) {
      searchLocation({ params: `q=${text}&type=municipality` })
        .unwrap()
        .then((res) => {
          setFeatures(res.features);
        });
    } else {
      setFeatures([]);
    }
  }, [text]);
  return (
    <VStack>
      <HStack>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={{ base: 4, md: 150 }}
          alignItems="center"
          justifyContent={"start"}
          justifyItems={{
            base: "center",
            md: "center",
          }}
        >
          <React.Fragment>
            <PrimaryHeading
              variant={"h2"}
              description={
                "Sert à calculer la position des panneaux pour estimer la production photovoltaïque"
              }
            >
              Code Postal/Ville
            </PrimaryHeading>

            <Controller
              name={"ville"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <HStack
                  wrap={"wrap"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  justifyItems={"center"}
                >
                  <PrimaryInput
                    placeholder="Code postal ou ville"
                    value={text || ""}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    width="200px"
                  />
                  <PrimarySelect
                    name="city"
                    value={value.id || ""}
                    onChange={(e) => {
                      const findElt = features.filter(
                        (f) => f.properties.id == e.target.value
                      );
                      if (findElt && findElt.length > 0) {
                        const f = findElt[0];
                        onChange({
                          id: f.properties.id,
                          city: f.properties.city,
                          latitude: f.geometry.coordinates[0],
                          longitude: f.geometry.coordinates[1],
                          departement: f.properties.context.slice(0, 2),
                        });
                      }
                    }}
                    width="200px"
                    options={
                      features.length > 0
                        ? features.map((feature) => ({
                            key: feature.properties.id,
                            value: feature.properties.id,
                            viewValue: feature.properties.city,
                          }))
                        : [
                            {
                              key: defaultVille.id,
                              value: defaultVille.id,
                              viewValue: defaultVille.city,
                            },
                          ]
                    }
                  />
                  {features && features.length > 0 && (
                    <Text> {features.length} ville(s) trouvée(s)</Text>
                  )}
                  {error && (
                    <EmsAlert
                      status="error"
                      title={
                        ("status" in error
                          ? getLoadingDataErrorByStatus(error.status).titre
                          : getLoadingDataErrorByStatus(undefined).titre) +
                        " Lors du chargement des villes"
                      }
                      description={
                        "status" in error
                          ? getLoadingDataErrorByStatus(error.status).message
                          : getLoadingDataErrorByStatus(undefined).message
                      }
                    />
                  )}
                </HStack>
              )}
            />
          </React.Fragment>
        </Grid>
      </HStack>
    </VStack>
  );
};
export const ProductionPage: React.FC = () => {
  const { control, handleSubmit, setValue } = useForm<InstallationPVData>({
    defaultValues: selectCalculatorData(store.getState()).installationPv,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data: InstallationPVData) => {
    // Dispatch the action to save the first form's data in the store
    dispatch(setInstallationPVData(data));
    // Navigate to the second form page
    navigate("/autoconsommation");
  };

  return (
    <Box m={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          //templateColumns={{ base: "1fr", md: "1fr" }}
          gap={{ base: 4, md: 4 }}
          alignItems="center"
          justifyContent={"start"}
          justifyItems={{
            base: "start",
            md: "center",
          }}
        >
          <GridItem colSpan={2} w={"full"} justifyItems={"start"}>
            <VilleDropdown
              control={control}
              setValue={setValue}
              defaultVille={DEFAULT_VILLE}
            />
          </GridItem>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }} // 2 colonnes sur grand écran
            gap={4} // Espacement entre les colonnes
            alignItems="center"
            justifyContent="start"
          >
            <RoofPowerCalculator control={control} setValue={setValue} />
          </Grid>
        </Grid>

        <React.Fragment>
          <FormLabel>
            <PrimaryHeading variant={"h2"}>
              Orientation des Panneaux
            </PrimaryHeading>
          </FormLabel>
          <VStack
            aspectRatio={"auto"}
            justifyContent={"center"}
            display={"flex"}
            alignItems={"center"}
            h={"full"}
            w={"full"}
            px={10}
            py={2}
          >
            <RadioGroupField
              name="orientation"
              options={ORIENTATION_OPTIONS}
              defaultValue={
                selectCalculatorData(store.getState()).installationPv
                  .orientation + ""
              }
              setValue={setValue}
              boxW={{ base: "100px", md: "100px", lg: "230px" }}
              boxH={{ base: "100px", md: "100px", lg: "150px" }}
            />
          </VStack>
        </React.Fragment>
        <React.Fragment>
          <FormLabel>
            <PrimaryHeading variant={"h2"} description="">
              Angle Toiture
            </PrimaryHeading>
          </FormLabel>
          <VStack
            aspectRatio={"auto"}
            justifyContent={"center"}
            display={"flex"}
            alignItems={"center"}
            h={"full"}
            w={"full"}
            px={10}
            py={2}
          >
            <RadioGroupField
              name="toiture"
              options={ANGLE_OPTIONS}
              defaultValue={
                selectCalculatorData(store.getState()).installationPv.toiture +
                ""
              }
              setValue={setValue}
              boxW={{ base: "100px", md: "100px", lg: "230px" }}
              boxH={{ base: "100px", md: "100px", lg: "150px" }}
            />
          </VStack>
        </React.Fragment>

        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
