// src/components/ProfilOptionCard.tsx
import React from "react";
import { RadioCard } from "./RadioCard";
import { VStack, Box, Text, Image } from "@chakra-ui/react";

// Vous pouvez adapter ou importer l'interface Profil depuis un fichier de types commun
export interface Profil {
  name: string;
  viewName: string;
  subtext: string;
  image: string;
  description: string;
}

interface ProfilOptionCardProps {
  profil: Profil;
  radioProps: any; // Typage provenant de useRadioGroup (peut être affiné)
}

export const ProfilOptionCard: React.FC<ProfilOptionCardProps> = ({
  profil,
  radioProps,
}) => {
  return (
    <VStack flex={1} gap={5} h="auto">
      <RadioCard {...radioProps} description={profil.description}>
        <Box
          w={{ base: "100px", md: "150px", lg: "300px" }}
          h={{ base: "150px", md: "200px", lg: "250px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          //overflow="hidden"
          position="relative"
          // Animation simple : au hover, on agrandit légèrement l'image
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <VStack spacing={{ base: 2, md: 4, lg: 5 }} align="center" mt={2}>
            <Text
              fontSize={{ base: "18px", md: "20px", lg: "24px" }}
              //color="gray.900"
              fontWeight="bold"
            >
              {profil.viewName}
            </Text>
            <Text
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
              color="gray.500"
            >
              {profil.subtext}
            </Text>
            <Image
              src={profil.image}
              alt={profil.viewName}
              objectFit="contain"
              w="75%"
            />
          </VStack>
        </Box>
      </RadioCard>
    </VStack>
  );
};
