import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormLabel,
  FormControl,
  Skeleton,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { PrimaryButton } from "../components/PrimaryButton";
import { PrimaryHeading } from "../components/PrimaryHeading";
import { RadioGroupField } from "../components/RadioGroupField";
import {
  InstallSizeData,
  OptiSizeData,
  useOptisizeMutation,
} from "../services/calculatorService";
import { store } from "../reducers/reducer";
import {
  BatteryData,
  ConsumptionData,
  selectCalculatorData,
  setBatteryData,
} from "../store/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InstallationSizesDisplay } from "../components/InstallationSizesDisplay";

interface Option {
  value: string;
  viewValue: string;
  description: string;
  install?: keyof OptiSizeData;
}

const INSTALLATION_OPTIONS: Option[] = [
  {
    value: "roi_power",
    install: "roi_power",
    viewValue: "ROI Power",
    description:
      "Ceci correspond à l’installation la plus grande qui permet de rester autour de 10 ans avant le remboursement de son investissement Le ratio de taille entre les panneaux et les batteries ont été optimisés pour avoir la meilleure rentabilité ",
  },
  {
    value: "smart_storage",
    viewValue: "Smart Storage",
    install: "smart_storage",
    description:
      "Ceci correspond à l’installation la plus grande qui assure un taux d’autoconsommation supérieur à 70%. Le ratio de taille entre les panneaux et les batteries ont été optimisés pour avoir la meilleure rentabilité ",
  },
  {
    value: "max_autonomy",
    viewValue: "Off-Grid Champion",
    install: "max_autonomy",
    description:
      "Ceci correspond à l’installation avec la plus grande zone de PV pouvant être installée. Le ratio de taille entre les panneaux et les batteries ont été optimisés pour avoir la meilleure rentabilité ",
  },
  {
    value: "perso",
    viewValue: "Personnalisée",
    description:
      "Permet de modifier les tailles de batteries et de panneaux. Les prix des installations sont fixes à 1300€/kWc pour les PV et 500€/KWh pour les batteries",
  },
];
export const BatteryPage: React.FC = () => {
  const { control, handleSubmit, setValue, getValues, watch } =
    useForm<BatteryData>({
      defaultValues: {
        installation:
          selectCalculatorData(store.getState()).battery.installation ||
          "smart_storage",
        annual_savings: selectCalculatorData(store.getState()).battery
          .annual_savings,
        battery_cost: selectCalculatorData(store.getState()).battery
          .battery_cost,
        battery_size: selectCalculatorData(store.getState()).battery
          .battery_size,
        inverter_size: selectCalculatorData(store.getState()).battery
          .inverter_size,
        pv_cost: selectCalculatorData(store.getState()).battery.pv_cost,
        pv_size: selectCalculatorData(store.getState()).battery.pv_size,
        tac: selectCalculatorData(store.getState()).battery.tac,
      },
    });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optiSizeData, setOptiSizeData] = useState<OptiSizeData>();
  console.log(optiSizeData ? optiSizeData["max_autonomy"] : undefined);
  const consumptionData = selectCalculatorData(store.getState()).consumption;
  const installationPvData = selectCalculatorData(
    store.getState()
  ).installationPv;
  const [optisizeFn, { isError, isLoading }] = useOptisizeMutation();
  const getPrices = (consumptionData: ConsumptionData) => {
    if (consumptionData.tarif == "base") {
      return [consumptionData.base.base];
    } else if (consumptionData.tarif == "hp_hc") {
      return [consumptionData.hp_hc.hp, consumptionData.hp_hc.hc];
    } else if (consumptionData.tarif == "4cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
      ];
    } else if (consumptionData.tarif == "5cadrans") {
      return [
        consumptionData["4cadrans"].hphs,
        consumptionData["4cadrans"].hchs,
        consumptionData["4cadrans"].hpbs,
        consumptionData["4cadrans"].hcbs,
        consumptionData["5cadrans"].pte,
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    const optisize = () => {
      optisizeFn({
        profil_client: consumptionData.profil,
        conso: Number(consumptionData.annual_conso) * 1000,
        angle: installationPvData.toiture,
        azimuth: installationPvData.orientation,
        departement: installationPvData.ville.departement,
        latitute: installationPvData.ville.latitude,
        longitute: installationPvData.ville.longitude,
        maxpeakpower: installationPvData.power,
        prices: getPrices(consumptionData),
        type: consumptionData.tarif,
      })
        .unwrap()
        .then((res) => {
          setOptiSizeData(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    optisize();
  }, [consumptionData, installationPvData]);

  useEffect(() => {
    const installation = getValues("installation");
    const data = optiSizeData?.[installation as keyof OptiSizeData];
    if (data) {
      dispatch(
        setBatteryData({
          installation: installation,
          ...data,
        })
      );
      Object.keys(data).forEach((key) => {
        const v = data[key as keyof InstallSizeData];
        setValue(
          key as keyof InstallSizeData,
          // eslint-disable-next-line no-unsafe-optional-chaining
          Number(v.toFixed(2))
        );
      });
    } else {
      dispatch(
        setBatteryData({
          installation: installation,
        })
      );
    }
  }, [watch("installation"), setValue, optiSizeData]);

  const onSubmit = (data: BatteryData) => {
    dispatch(setBatteryData(data));
    // Navigate to the second form page
    navigate("/bilanEconomique");
  };

  return isError ? (
    <Box>Erreur</Box>
  ) : (
    <Box p={4} flex={1} h={"full"}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "100%",
          gap: 5,
        }}
      >
        <FormControl as="fieldset" gap={3}>
          <FormLabel justifyItems={"center"}>
            <PrimaryHeading variant={"h1"}>
              Taille d&apos;installation Conseillée
            </PrimaryHeading>
          </FormLabel>

          <RadioGroupField
            name="installation"
            options={INSTALLATION_OPTIONS}
            boxH={{ base: "200px", md: "250px", lg: "300px" }}
            defaultValue={
              selectCalculatorData(store.getState()).battery.installation ||
              "smart_storage"
            }
            setValue={setValue}
            renderExtra={(option) => {
              const data =
                optiSizeData && option.install
                  ? optiSizeData[option.install]
                  : undefined;

              return (
                <InstallationSizesDisplay
                  battery_size={data?.battery_size}
                  pv_size={data?.pv_size}
                  inverter_size={data?.inverter_size}
                />
              );
            }}
          />
        </FormControl>
        <Grid
          templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          templateRows={"auto"}
          gap={4}
          py={2}
          alignItems="center"
          justifyContent={"center"}
          justifyItems={{
            base: "center",
            md: "center",
          }}
        >
          {[
            {
              value: "battery_size",
              viewValue: "Capacité Batterie",
              description: "",
            },
            {
              value: "pv_size",
              viewValue: "Puissance PV",
            },
            {
              value: "inverter_size",
              viewValue: "Puissance Batterie",
            },
          ].map(
            (field) =>
              getValues("installation") == "perso" && (
                <React.Fragment key={field.value}>
                  <Skeleton isLoaded={!isLoading}>
                    <PrimaryHeading variant={"h2"}>
                      {field.viewValue}
                    </PrimaryHeading>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading}>
                    <Box>
                      <Controller
                        name={field.value}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            {...controllerField}
                            value={controllerField.value}
                            isDisabled={getValues("installation") !== "perso"}
                            bg={
                              getValues("installation") !== "perso"
                                ? "red"
                                : "black"
                            }
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </Box>
                  </Skeleton>
                </React.Fragment>
              )
          )}
        </Grid>
        {getValues("installation") !== "perso" && (
          <React.Fragment>
            <Divider
              borderWidth={"2px"}
              borderColor={"gray.900"}
              width={["full", "50%"]}
              mx={"auto"}
            />
            <Grid
              templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
              templateRows={"auto"}
              gap={4}
              py={2}
              alignItems="center"
              justifyContent={"center"}
              justifyItems={{
                base: "center",
                md: "center",
              }}
            >
              {[
                {
                  value: "battery_cost",
                  viewValue: "Prix estimé batterie",
                  description:
                    "Les prix des installations sont fixes à 1300€/kWc pour les PV et 500€/KWh pour les batteries",
                },

                {
                  value: "pv_cost",
                  viewValue: "Prix estimé PV",
                  description:
                    "Les prix des installations sont fixes à 1300€/kWc pour les PV et 500€/KWh pour les batteries",
                },

                {
                  value: "tac",
                  viewValue: "Taux d’autoconsommation",
                  description:
                    "Correspond au ratio entre l’energie solaire consommée et l’energie totale produite. Un taux à 100% indique que l’intégralité de la production est locale!",
                },
                {
                  value: "annual_savings",
                  viewValue: "Economies annuelles",
                  description:
                    "Correspond aux economies sur une année de la réduction de facture d’electricité et de la revente du surplus",
                },
              ].map((field) => (
                <React.Fragment key={field.value}>
                  <Skeleton isLoaded={!isLoading}>
                    <Tooltip label={field.description}>
                      <PrimaryHeading variant={"h2"}>
                        {field.viewValue}
                      </PrimaryHeading>
                    </Tooltip>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading}>
                    <Box>
                      <Controller
                        name={field.value}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            {...controllerField}
                            value={controllerField.value}
                            isDisabled
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </Box>
                  </Skeleton>
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        )}
        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
