// src/components/InstallationSizesDisplay.tsx
import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { SunIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { FaBatteryHalf, FaSolarPanel } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";
interface InstallationSizesDisplayProps {
  battery_size?: number;
  pv_size?: number;
  inverter_size?: number;
}

export const InstallationSizesDisplay: React.FC<
  InstallationSizesDisplayProps
> = ({ battery_size, pv_size, inverter_size }) => {
  // Si toutes les valeurs sont undefined, on ne renvoie rien
  if (
    battery_size === undefined &&
    pv_size === undefined &&
    inverter_size === undefined
  ) {
    return null;
  }

  return (
    <Box mt={2}>
      {/* Ligne pour le PV (on utilise SunIcon comme substitut) */}
      {pv_size !== undefined && (
        <Stack direction="row" align="center" spacing={2} mb={2}>
          <Icon
            as={FaSolarPanel as React.ElementType}
            boxSize="48px"
            color="content.secondary.600"
          />
          <Text fontSize="xl">{pv_size.toFixed(2)} KWc</Text>
        </Stack>
      )}

      {/* Ligne pour la batterie + onduleur (on utilise TriangleDownIcon comme substitut) */}
      {(battery_size !== undefined || inverter_size !== undefined) && (
        <Stack direction="row" align="center" spacing={2}>
          <Icon
            as={FaBatteryHalf as React.ElementType}
            boxSize="48px"
            color="content.secondary.600"
          />

          <Box lineHeight="shorter">
            {battery_size !== undefined && (
              <Text fontSize="xl">{battery_size.toFixed(2)}/kWh</Text>
            )}
            {inverter_size !== undefined && (
              <Text fontSize="xl">{inverter_size.toFixed(2)}/kW</Text>
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
};
