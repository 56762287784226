import { Grid, GridItem, useTheme } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { Header } from "../components/Header";

interface GlobalLayoutProps {
  children?: ReactNode;
}

export const GlobalLayout: React.FC<GlobalLayoutProps> = () => {
  const theme = useTheme(); // 🔥 Get current theme (which includes backgroundImage)
  return (
    <Grid
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(12, 1fr)"
      height={"100vh"}
      bgImage={theme.backgroundImage ? `url(${theme.backgroundImage})` : "none"}
      bg={
        theme.backgroundImage ? undefined : theme.colors.content.secondary[50]
      }
      bgSize="cover" // Couvre tout l'écran
      bgPosition="center" // Centre l'image
      bgRepeat="no-repeat" // Empêche la répétition
      bgAttachment="fixed" // Fixe l'image lors du scroll
    >
      <GridItem
        rowSpan={{
          base: 1,
          md: 1,
          lg: 1,
        }}
        colSpan={{
          base: 12,
          md: 12,
          lg: 12,
        }}
      >
        <Header />
      </GridItem>
      <GridItem
        rowSpan={{
          base: 2,
          md: 11,
          lg: 11,
        }}
        colSpan={{
          base: 12,
          md: 3,
          lg: 1,
        }}
      >
        <NavBar />
      </GridItem>

      <GridItem
        rowSpan={{
          base: 9,
          md: 11,
          lg: 11,
        }}
        colSpan={{
          base: 12,
          md: 9,
          lg: 11,
        }}
        overflowY={"auto"}
        py={4}
      >
        <Outlet />
      </GridItem>
    </Grid>
  );
};
