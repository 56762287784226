import {
  Box,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Stack,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RadioCard } from "../components/RadioCard";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton } from "../components/PrimaryButton";
import { PrimaryInput } from "../components/PrimaryInput";
import { PrimaryHeading } from "../components/PrimaryHeading";
import { FormLabelInput } from "../components/FormLabelInput";
import { Image } from "@chakra-ui/react";
import {
  ConsumptionData,
  selectCalculatorData,
  setConsumptionData,
} from "../store/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "../reducers/reducer";
import { ProfilOptionCard } from "../components/ProfilOptionCard";

interface Profil {
  name: string;
  viewName: string;
  subtext: string;
  image: string;
  description: string;
}
interface PowerOption {
  annual_conso: number;
  unit: string;
  invoice: string;
}
interface TarifOption {
  value: string;
  viewValue: string;
}

export const ConsumptionPage: React.FC = () => {
  const profilOptions: Profil[] = [
    {
      name: "thermostable",
      viewName: "Consommation stable et continue",
      subtext: "",
      image: "/dynamic_assets/img/anim_thermostable.svg",
      description:
        "Entreprises avec groupes froids, climatisation, régulation thermique. Exemples : Industries agroalimentaires, data centers, hôpitaux",
    },
    {
      name: "solar_aligned",
      viewName: "Consommation en journée",
      image: "/dynamic_assets/img/anim_solar_aligned.svg",
      subtext: "",
      description:
        "Bureaux, artisans, commerces qui consomment pendant les heures solaires. Exemples : Coiffeurs, agences, magasins, ateliers, petits industriels",
    },
    {
      name: "hybrid_demand",
      viewName: "Consommation mixte entre jour et soir",
      image: "/dynamic_assets/img/anim_hybrid.svg",
      subtext: "",
      description:
        "Restaurants, hôtels, boulangeries, entreprises avec stockage thermique. Exemples : Restauration, bars, hôtels, petites industries avec frigos.",
    },
  ];
  const powerOptions: PowerOption[] = [
    {
      annual_conso: 30,
      unit: "MWh",
      invoice: "4500€HTVA",
    },
    {
      annual_conso: 75,
      unit: "MWh",
      invoice: "11000€HTVA",
    },
    {
      annual_conso: 150,
      unit: "MWh",
      invoice: "22000€HTVA",
    },
    {
      annual_conso: 300,
      unit: "MWh",
      invoice: "44000€HTVA",
    },
  ];
  const tarifOptions: TarifOption[] = [
    {
      value: "base",
      viewValue: "BASE",
    },
    {
      value: "hp_hc",
      viewValue: "HP/HC",
    },
    {
      value: "4cadrans",
      viewValue: "4CADRANS",
    },
    {
      value: "5cadrans",
      viewValue: "5CADRANS",
    },
  ];
  const {
    getValues,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ConsumptionData>({
    defaultValues: selectCalculatorData(store.getState()).consumption,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: ConsumptionData) => {
    // Dispatch the action to save the first form's data in the store
    console.log("data", data);

    dispatch(setConsumptionData(data));
    // Navigate to the second form page
    navigate("/installationPv");
  };
  const {
    getRootProps: getRootPropsProfil,
    getRadioProps: getRadioPropsProfil,
  } = useRadioGroup({
    name: "profils",
    defaultValue: selectCalculatorData(store.getState()).consumption.profil,
    onChange: (value: string) => setValue("profil", value),
  });
  const { getRootProps: getRootPropsPower, getRadioProps: getRadioPropsPower } =
    useRadioGroup({
      name: "powers",
      defaultValue:
        selectCalculatorData(store.getState()).consumption.annual_conso + "",
      onChange: (value: string) => setValue("annual_conso", value),
    });
  const {
    getRootProps: getRootPropsTarifs,
    getRadioProps: getRadioPropsTarifs,
  } = useRadioGroup({
    name: "tarifs",
    defaultValue: selectCalculatorData(store.getState()).consumption.tarif,
    onChange: (value: "hp_hc" | "4cadrans" | "5cadrans" | "base") => {
      setValue("tarif", value);
    },
  });

  const groupProfils = getRootPropsProfil();
  const groupPower = getRootPropsPower();
  const groupTarif = getRootPropsTarifs();

  return (
    <Box m={2}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          flex: 1,
          height: "100%",
        }}
      >
        <FormControl as="fieldset" mb={4}>
          <PrimaryHeading variant={"h1"}>PROFIL DE CONSOMMATION</PrimaryHeading>
          <Flex
            {...groupProfils}
            flexDirection="row"
            wrap="wrap"
            gap={4}
            justifyContent="space-around"
          >
            {profilOptions.map((item, key) => {
              const radio = getRadioPropsProfil({ value: item.name });
              return (
                <ProfilOptionCard key={key} profil={item} radioProps={radio} />
              );
            })}
          </Flex>
        </FormControl>

        <FormControl as="fieldset" mb={4}>
          <PrimaryHeading variant={"h1"}>CONSOMMATION ANNUELLE</PrimaryHeading>
          <Flex
            {...groupPower}
            flexDirection={"row"}
            wrap={"wrap"}
            gap={4}
            justifyContent={"space-around"}
          >
            {powerOptions.map((item: PowerOption, key: number) => {
              const radio = getRadioPropsPower({
                value: item.annual_conso + "",
              });
              return (
                <VStack key={key}>
                  <Box>
                    <RadioCard {...radio}>
                      <VStack
                        aspectRatio={"auto"}
                        justifyContent={"center"}
                        display={"flex"}
                        alignItems={"center"}
                        h={"full"}
                        w={"full"}
                      >
                        <Text size="md"> {item.annual_conso + item.unit}</Text>
                        <Text
                          color={"gray.500"}
                          fontSize={{ base: "6px", md: "8px", lg: "10px" }}
                        >
                          {" "}
                          Facture Annuelle: {item.invoice}
                        </Text>
                      </VStack>
                    </RadioCard>
                  </Box>
                </VStack>
              );
            })}
          </Flex>
          <PrimaryHeading
            variant={"h1"}
            description={
              <VStack spacing={2} align="start" w="600px">
                <Text fontWeight="bold">Renseignez vous sur votre facture</Text>
                <Image
                  src="/dynamic_assets/img/facturetest.png"
                  alt="facture"
                  w="600px"
                  h="auto"
                  objectFit="contain"
                />
                <Text fontSize="sm">
                  Utilisé pour les toits plats ou au sol.
                </Text>
              </VStack>
            }
          >
            TYPE DE TARIFICATION
          </PrimaryHeading>
          <Flex
            {...groupTarif}
            flexDirection={"row"}
            wrap={"wrap"}
            gap={4}
            justifyContent={"space-around"}
          >
            {tarifOptions.map((item: TarifOption, key: number) => {
              const radio = getRadioPropsTarifs({ value: item.value });
              return (
                <VStack key={key}>
                  <Box>
                    <RadioCard {...radio}>
                      <VStack
                        aspectRatio={"auto"}
                        justifyContent={"center"}
                        display={"flex"}
                        alignItems={"center"}
                        h={"full"}
                        w={"full"}
                        px={10}
                        py={2}
                      >
                        <Text size="md"> {item.viewValue}</Text>
                      </VStack>
                    </RadioCard>
                  </Box>
                </VStack>
              );
            })}
          </Flex>
        </FormControl>
        <Box
          w={"full"}
          flex={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* BASE */}
          {getValues("tarif") === "base" && (
            <VStack
              spacing={4}
              align="center"
              p={2}
              borderRadius="md"
              mx={"auto"}
            >
              <FormControl display="flex" alignItems="center" mx={"auto"}>
                <FormLabelInput as={"label"}>
                  <Text>{`Tarif BASE`}</Text>
                </FormLabelInput>
                <Controller
                  name="base.base"
                  control={control}
                  render={({ field }) => (
                    <PrimaryInput
                      type="number"
                      width="150px"
                      isRequired
                      {...field}
                      value={field.value} // Évite les erreurs d'entrée non contrôlée
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  )}
                />
              </FormControl>
            </VStack>
          )}

          {/* HPHC */}
          {getValues("tarif") === "hp_hc" && (
            <VStack spacing={4} align="center" p={2}>
              <Grid
                //templateRows="repeat(2, auto)"
                templateColumns="repeat(2, 2fr)"
                mx="auto"
                p={2}
                gap={{ sm: 4, md: 10 }} // Moved rowGap & columnGap here
              >
                {["hp", "hc"].map((field) => (
                  <GridItem mb={4} key={field} colSpan={{ base: 2, md: 1 }}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabelInput as={"label"}>
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabelInput>
                      <Controller
                        name={`hp_hc.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            {...controllerField}
                            isRequired
                            value={controllerField.value} // Prevents uncontrolled input issues
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}

          {/* 4CADRANS */}
          {getValues("tarif") === "4cadrans" && (
            <VStack spacing={4} align="center">
              <Grid
                templateColumns="repeat(2, 2fr)" // Maximum 2 colonnes
                mx="auto"
                p={2}
                gap={{ base: 4, md: 10 }} // Espacement entre les éléments
              >
                {["hcbs", "hchs", "hpbs", "hphs"].map((field) => (
                  <GridItem key={field} colSpan={{ base: 2, md: 1 }}>
                    {" "}
                    {/* Fix du colSpan */}
                    <FormControl display="flex" alignItems="center">
                      <FormLabelInput as={"label"}>
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabelInput>
                      <Controller
                        name={`4cadrans.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            isRequired
                            {...controllerField}
                            value={controllerField.value} // Évite les erreurs d'entrée
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}

          {/* 5CADRANS */}
          {getValues("tarif") === "5cadrans" && (
            <VStack spacing={4} align="center" p={2}>
              <Grid
                //templateRows="repeat(2, auto)"
                templateColumns="repeat(2, 2fr)"
                mx="auto"
                p={2}
                gap={{ sm: 4, md: 10 }} // Moved rowGap & columnGap here
              >
                {["hcbs", "hchs", "hpbs", "hphs", "pte"].map((field) => (
                  <GridItem mb={4} key={field} colSpan={{ base: 2, md: 1 }}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabelInput as={"label"}>
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabelInput>
                      <Controller
                        name={`5cadrans.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            {...controllerField}
                            isRequired
                            value={controllerField.value} // Prevents uncontrolled input issues
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}
        </Box>

        <Flex flexDir={"row"} justifyContent={"end"} m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Flex>
      </form>
    </Box>
  );
};
