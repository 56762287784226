// src/components/Header.tsx
import React from "react";
import { Flex, Box, Spacer, Image, Link } from "@chakra-ui/react";
import ThemeSelector from "./ThemeSelector";

// Importation des logos
import noeLogo from "../assets/img/logos/noe_blanc.svg";
import ifpenLogo from "../assets/img/logos/ifpen_blanc.png";
import soalisLogo from "../assets/img/logos/soalis_blanc.png";
import { Link as RouterLink } from "react-router-dom";
import AppRoutes from "../routes/Routes";

export const Header: React.FC = () => {
  return (
    <Flex
      direction="row"
      align="center"
      justify="space-between"
      height="full"
      bg="nav.primary.500" // valeur dynamique du thème
      color="nav.secondary.100" // valeur dynamique du thème
      fontSize={{ base: "24px", md: "30px", lg: "36px" }} // responsive
      p={4}
    >
      {/* Section gauche : Logo NEO */}
      <Box>
        <Link as={RouterLink} to="/Accueil">
          <Image
            src={noeLogo}
            alt="Logo NEO"
            height={{ base: "30px", md: "40px", lg: "70px" }}
            cursor="pointer"
          />
        </Link>
      </Box>
      <Spacer />
      {/* Section droite : Logos Ifpen et Soalis */}
      <Box display="flex" alignItems="center" gap={4}>
        <Image
          src={ifpenLogo}
          alt="Logo Ifpen"
          height={{ base: "30px", md: "40px", lg: "70px" }}
        />
        <Image
          src={soalisLogo}
          alt="Logo Soalis"
          height={{ base: "30px", md: "40px", lg: "60px" }}
        />
      </Box>
      {/* ThemeSelector peut être positionné ici ou déplacé selon le design souhaité */}
      {
        //<ThemeSelector />
      }
    </Flex>
  );
};

export default Header;
