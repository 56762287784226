import { Grid, Tooltip } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { PrimaryHeading } from "./PrimaryHeading";
import { PrimaryInput } from "./PrimaryInput";
import React from "react";
interface RoofPowerCalculatorProps {
  control: any;
  setValue: (name: string, value: any) => void;
}

export const RoofPowerCalculator: React.FC<RoofPowerCalculatorProps> = ({
  control,
  setValue,
}) => {
  return (
    <Grid
      templateColumns={{ base: "1fr", md: "3fr 1fr" }}
      gap={4}
      alignItems="center"
    >
      {/* Taille Toiture */}

      <PrimaryHeading
        description={
          "Taille estimée de Toiture qui est exploitable pour mettre des Panneaux. On l’estime à 60% de l’empreinte au sol dans les toitures en pente  et 80% pour les toitures plates"
        }
        variant="h2"
      >
        Taille estimée centrale solaire (m²)
      </PrimaryHeading>

      <Controller
        name="tailleToiture"
        control={control}
        render={({ field }) => (
          <PrimaryInput
            type="number"
            width="150px"
            {...field}
            onBlur={() => {
              if (field.value) {
                setValue("power", (parseFloat(field.value) * 0.22).toFixed(0));
              }
            }}
          />
        )}
      />

      {/* Puissance Installable */}

      <PrimaryHeading
        variant="h2"
        description="Taille estimée de Toiture qui est exploitable pour mettre des Panneaux"
      >
        Puissance installable (kWc)
      </PrimaryHeading>
      <Controller
        name="power"
        control={control}
        render={({ field }) => (
          <PrimaryInput
            type="number"
            width="150px"
            {...field}
            onBlur={() => {
              if (field.value) {
                setValue(
                  "tailleToiture",
                  (parseFloat(field.value) / 0.22).toFixed(0)
                );
              }
            }}
          />
        )}
      />
    </Grid>
  );
};
