import { extendTheme } from "@chakra-ui/react";

export type ThemeName = "noe" | "solar" | "forest";

const themes: Record<ThemeName, any> = {
  forest: {
    colors: {
      nav: {
        primary: {
          50: "#e6f4eb88",
          100: "#cde4d688",
          200: "#9ec3ae88",
          300: "#75a58e88",
          400: "#4c866e88",
          500: "#2D6A4F88",
          600: "#265a4488",
          700: "#1f4a3988",
          800: "#183a2e88",
          900: "#112a2388",
        },
        secondary: {
          50: "#E9F5E6", // Lightest green (background)
          100: "#D4EED0",
          200: "#B0DFA8",
          300: "#8DC181",
          400: "#6AA459",
          500: "#FFB703", // Main Green (Buttons, active states)
          600: "#3E7229",
          700: "#2D5A1E",
          800: "#1E4214", // Dark green for contrast
          900: "#102A09", // Deepest green (shadows, strong contrast)
        },
      },
      content: {
        primary: {
          50: "#e6f4eb",
          100: "#cde4d6",
          200: "#9ec3ae",
          300: "#75a58e",
          400: "#4c866e",
          500: "#2D6A4F",
          600: "#265a44",
          700: "#1f4a39",
          800: "#183a2e",
          900: "#112a23",
        },
        secondary: {
          50: "#E9F5E6", // Lightest green (background)
          100: "#D4EED0",
          200: "#B0DFA8",
          300: "#8DC181",
          400: "#6AA459",
          500: "#4F8A34", // Main Green (Buttons, active states)
          600: "#3E7229",
          700: "#2D5A1E",
          800: "#1E4214", // Dark green for contrast
          900: "#102A09", // Deepest green (shadows, strong contrast)
        },
      },
      gray: {
        50: "#F8F9FA",
        100: "#E9ECEF",
        200: "#DEE2E6",
        300: "#CED4DA",
        400: "#ADB5BD",
        500: "#6C757D", // Main text color
        600: "#495057",
        700: "#343A40",
        800: "#212529",
        900: "#121416",
      },
    },
    backgroundImage: "/dynamic_assets/img/background/battery_bg.svg",
  },

  solar: {
    colors: {
      nav: {
        primary: {
          50: "#E8F1FA88", // Light blue for backgrounds
          100: "#D0E2F688",
          200: "#A8C6EC88",
          300: "#7AAAE188",
          400: "#4D8ED688",
          500: "#05276C88", // Primary Blue (good for text, borders)
          600: "#0B61E188",
          700: "#094DBA88",
          800: "#073A9388",
          900: "#05276C88", // Deep navy for contrast
        },
        secondary: {
          50: "#FFF9E6", // Lightest yellow for backgrounds
          100: "#FFE8A3",
          200: "#FCE588",
          300: "#FADB5F",
          400: "#F7C948",
          500: "#FFB703", // Strong Yellow for primary buttons (like "Suivant")
          600: "#E69C02", // Slightly darker for hover states
          700: "#CC8701",
          800: "#B37200", // Even darker for selection states
          900: "#8A5600", // Deepest yellow-brown for shadows
        },
      },
      content: {
        primary: {
          50: "#FFF9E6", // Lightest yellow for backgrounds
          100: "#FFE8A3",
          200: "#FCE588",
          300: "#FADB5F",
          400: "#F7C948",
          500: "#FFB703", // Strong Yellow for primary buttons (like "Suivant")
          600: "#E69C02", // Slightly darker for hover states
          700: "#CC8701",
          800: "#B37200", // Even darker for selection states
          900: "#8A5600", // Deepest yellow-brown for shadows
        },
        secondary: {
          50: "#FDEFE6", // Very light orange
          100: "#FADFCD", // Soft pastel orange
          200: "#F6BE9B", // Warm peachy orange
          300: "#F19E68", // Medium-light orange
          400: "#ED7D36", // Bright tangerine
          500: "#E85D04", // Base powerful orange
          600: "#BA4A03", // Deeper burnt orange
          700: "#8B3802", // Dark rich orange
          800: "#5D2502", // Very dark rustic orange
          900: "#2E1301", // Near black deep orange
        },
      },
      gray: {
        50: "#F8F9FA",
        100: "#E9ECEF",
        200: "#DEE2E6",
        300: "#CED4DA",
        400: "#ADB5BD",
        500: "#6C757D", // Main text color
        600: "#495057",
        700: "#343A40",
        800: "#212529",
        900: "#121416",
      },
    },
    backgroundImage: "/dynamic_assets/img/background/solar_bg.jpg",
  },
  noe: {
    colors: {
      // Couleurs utilisées pour la barre de navigation / header
      nav: {
        // Couleur principale (ex: background du header)
        primary: {
          50: "#F0F2F6",
          100: "#D9DFE8",
          200: "#C2CCDF",
          300: "#A6B4D3",
          400: "#7C8DC0",
          500: "#1E396D", // Couleur "brand" principale
          600: "#1B3362",
          700: "#172B56",
          800: "#131E41",
          900: "#0E142B",
        },
        // Couleur secondaire (peut servir pour des hover, accents, sous-header)
        secondary: {
          50: "#F4F6FA",
          100: "#EAEDF5",
          200: "#D5DBEA",
          300: "#C0C8DF",
          400: "#ACB5D4",
          500: "#576FA0", // Bleu plus clair
          600: "#4E648F",
          700: "#464A75",
          800: "#303451",
          900: "#1F2234",
        },
      },

      // Couleurs utilisées pour le contenu principal (boutons, sections interactives)
      content: {
        // Couleur principale pour les CTA (ex: bouton "Suivant")
        primary: {
          50: "#F0F2F6",
          100: "#D9DFE8",
          200: "#C2CCDF",
          300: "#A6B4D3",
          400: "#7C8DC0",
          500: "#1E396D", // On peut réutiliser le bleu principal
          600: "#1B3362",
          700: "#172B56",
          800: "#131E41",
          900: "#0E142B",
        },
        // Couleur secondaire si besoin d'un second style de bouton ou d’accent
        secondary: {
          50: "#FCECEA",
          100: "#F8D1C7",
          200: "#F3B3A0",
          300: "#EE9578",
          400: "#E97750",
          500: "#E44223", // Orange/rouge "brand"
          600: "#C53B1F",
          700: "#A6341B",
          800: "#882C16",
          900: "#692512",
        },
      },

      // Echelle de gris pour le texte, fonds neutres, séparateurs...
      gray: {
        50: "#F8F9FA",
        100: "#E9ECEF",
        200: "#DEE2E6",
        300: "#CED4DA",
        400: "#ADB5BD",
        500: "#6C757D", // Couleur de texte par défaut
        600: "#495057",
        700: "#343A40",
        800: "#212529",
        900: "#121416",
      },
    },

    // Image ou dégradé en background global (optionnel)
  },
};

const createTheme = (themeName: ThemeName) =>
  extendTheme({
    backgroundImage: themes[themeName]["backgroundImage"],
    colors: themes[themeName]["colors"],
    components: {
      Button: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md",
        },
        variants: {
          solid: {
            bg: "primary.500",
            color: "primary.50",
            _hover: { bg: "primary.400", color: "primary.50" },
          },
          outline: {
            borderColor: "primary.500",
            color: "primary.500",
            _hover: { bg: "primary.100", color: "primary.700" },
          },
        },
      },
      Input: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md",
        },
        variants: {
          solid: {
            bg: "primary.500",
            color: "primary.50",
            _hover: { bg: "primary.400", color: "primary.50", border: "none" },
          },
          outline: {
            borderColor: "primary.500",
            color: "primary.500",
            _hover: { bg: "primary.100" },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md",
          color: "gray.900",
        },
      },
      Heading: {
        baseStyle: {
          fontWeight: "bold",
          color: "primary.500",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        sizes: {
          xl: { fontSize: "4xl" },
          lg: { fontSize: "3xl" },
          md: { fontSize: "2xl" },
          sm: { fontSize: "xl" },
        },
        variants: {
          primary: {
            color: "gray.900",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          },
          secondary: {
            color: "gray.600",
            fontWeight: "semibold",
          },
        },
        defaultProps: {
          size: "md",
          variant: "primary",
        },
      },
      Tooltip : {
        baseStyle: {
          fontWeight: "bold",
          color: "gray.500",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          backgroundColor:"white"

        },
      }
    },
    styles: {
      global: {
        "html, body": {
          backgroundColor: "gray.900",
          color: "gray.800",
          fontFamily: "'Inter', sans-serif",
          lineHeight: "base",
        },
      },
    },
  });

export { createTheme, themes };
