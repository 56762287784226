import { Select, SelectProps } from "@chakra-ui/react";
import React from "react";
interface Option {
  key: string;
  value: string;
  viewValue: string;
}
interface PrimarySelectProps {
  options: Option[];
}

export const PrimarySelect: React.FC<SelectProps & PrimarySelectProps> = ({
  ...props
}) => {
  return (
    <Select
      {...props}
      borderWidth="2px"
      borderRadius="md"
      boxShadow="0px 10px 10px rgba(0, 0, 0, 0.2)"
      textAlign="center"
      bg="secondary.500"
      color="primary.50"
      border={"none"}
    > 
      {props.options.map((option) => (
        <option
          style={{
            backgroundColor: "var(--chakra-colors-secondary-500)", // this will use the Chakra UI color value
            color: "var(--chakra-colors-primary-50)", // Chakra color reference
          }}
          key={option.key}
          value={option.value}
        >
          {option.viewValue}
        </option>
      ))}
    </Select>
  );
};
