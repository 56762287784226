import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { DoghnutData } from "../components/DoughnutGraph";
import { COLORS, getNumberFormatter } from "../utils/toolbox";
import {
  AutoConsommationIndicator,
  EnergyDistribution,
} from "../components/AutoConsommationIndicatorComponent";
import { PrimaryButton } from "../components/PrimaryButton";
import { selectCalculatorData, setConsumptionData } from "../store/config";
import { store } from "../reducers/reducer";
import { useSolarSimulationMutation } from "../services/calculatorService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";

export interface AutoConsommationCardProps {
  graphData: DoghnutData;
  title: string | ReactNode;
  energyDistribution: EnergyDistribution[];
}

export const AutoConsommationCard: React.FC<AutoConsommationCardProps> = ({
  graphData,
  title,
  energyDistribution,
}) => {
  return (
    <Box h="400px">
      <AutoConsommationIndicator
        graphData={graphData}
        title={title}
        energyDistribution={energyDistribution}
      />
    </Box>
  );
};

export const AutoConsommationPage: React.FC = () => {
  const labelsConso: string[] = ["Provenant du réseau", "Produit sur place"];
  const labelsProd: string[] = ["Export", "Consommé"];

  const [consoData, setConsoData] = useState<{
    energy_grid: number;
    energy_self_consumption: number;
  }>();
  const [prodData, setProdData] = useState<{
    energy_export: number;
    energy_self_consumption: number;
  }>();

  const doghnutDataProd: DoghnutData = {
    backgroundColor: [COLORS.EXPORT, COLORS.PROD],
    labels: labelsProd,
    data: prodData
      ? [prodData?.energy_export, prodData?.energy_self_consumption]
      : [],
  };

  const doghnutDataConso: DoghnutData = {
    backgroundColor: [COLORS.CONSO, COLORS.PROD],
    labels: labelsConso,
    data: consoData
      ? [consoData?.energy_grid, consoData?.energy_self_consumption]
      : [],
  };

  const prodEnergyDistribution: EnergyDistribution[] = [
    {
      value: prodData?.energy_export
        ? getNumberFormatter().format(prodData?.energy_export * 1000) + "Wh"
        : 0 + "Kwh",
      color: COLORS.EXPORT,
      label: "Export",
    },
    {
      value: prodData?.energy_self_consumption
        ? getNumberFormatter().format(
            prodData?.energy_self_consumption * 1000
          ) + "Wh"
        : 0 + "Kwh",
      color: COLORS.PROD,
      label: "Consommé",
    },
  ];

  const consoEnergyDistribution: EnergyDistribution[] = [
    {
      value: consoData?.energy_self_consumption
        ? getNumberFormatter().format(
            consoData?.energy_self_consumption * 1000
          ) + "Wh"
        : 0 + "Kwh",
      color: COLORS.PROD,
      label: "Produit sur place",
    },
    {
      value: consoData?.energy_grid
        ? getNumberFormatter().format(consoData?.energy_grid * 1000) + "Wh"
        : 0 + "Kwh",
      color: COLORS.CONSO,
      label: "Provenant du réseau",
    },
  ];
  const consumptionData = selectCalculatorData(store.getState()).consumption;
  const installationPvData = selectCalculatorData(
    store.getState()
  ).installationPv;

  const [simulate, { isError, isLoading }] = useSolarSimulationMutation();
  const runSimulation = useCallback(() => {
    const simulation = async () => {
      return await simulate({
        profil_client: consumptionData.profil,
        conso: Number(consumptionData.annual_conso) * 1000,
        angle: installationPvData.toiture,
        azimuth: installationPvData.orientation,
        departement: installationPvData.ville.departement,
        latitute: installationPvData.ville.latitude,
        longitute: installationPvData.ville.longitude,
        maxpeakpower: installationPvData.power,
      }).unwrap();
    };
    try {
      const response = simulation();
      response
        .then((simulationResult) => {
          setProdData({
            energy_export: simulationResult.energy_export,
            energy_self_consumption: simulationResult.energy_self_consumption,
          });
          setConsoData({
            energy_grid: simulationResult.energy_grid,
            energy_self_consumption: simulationResult.energy_self_consumption,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [consumptionData, installationPvData]);

  useEffect(() => {
    runSimulation();
  }, [runSimulation]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return isError ? (
    <HStack
      alignItems={"center"}
      display={"flex"}
      alignContent={"center"}
      pt={15}
      justifyContent={"center"}
    >
      <Heading
        variant={"primary"}
        textAlign={"center"}
        size={{ base: "sm", md: "md" }}
        color={"red"}
      >
        Une erreur est survenue !
      </Heading>
      <Button variant={"outline"} onClick={runSimulation}>
        Réessayer
      </Button>
    </HStack>
  ) : (
    <Box
      w={"full"}
      maxH={"fit-content"}
      p={2}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"start"}
    >
      <Heading
        variant={"primary"}
        textAlign={"center"}
        size={{ base: "sm", md: "md" }}
      >
        Si vous solarisez toute votre toiture:
      </Heading>
      <Box id="chart">
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={{ base: 4, md: 10 }}
          justifyItems="center"
          alignContent="center"
          alignItems="center"
          py={10}
        >
          <GridItem
            w="full"
            rowSpan={{ base: 1, md: 2 }}
            colSpan={{
              base: 2,
              md: 1,
            }}
          >
            <Skeleton isLoaded={!isLoading}>
              <AutoConsommationCard
                graphData={doghnutDataProd}
                title="Production"
                energyDistribution={prodEnergyDistribution}
              />
            </Skeleton>
          </GridItem>
          <GridItem
            w="full"
            rowSpan={{ base: 1, md: 2 }}
            colSpan={{
              base: 2,
              md: 1,
            }}
          >
            <Skeleton isLoaded={!isLoading}>
              <AutoConsommationCard
                graphData={doghnutDataConso}
                title="Consommation"
                energyDistribution={consoEnergyDistribution}
              />
            </Skeleton>
          </GridItem>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="end" m={2}>
        <PrimaryButton
          onClick={() => {
            // Cibler l'élément du composant à capturer
            const component = document.getElementById("chart");

            // Utiliser html2canvas pour capturer le composant en tant qu'image
            if (component) {
              html2canvas(component, {
                scale: 2, // Increase the quality of the image
                logging: false, // Disable logging for faster rendering
                useCORS: true, // If you have external images or resources
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg"); // Convert canvas to image data
                dispatch(
                  setConsumptionData({
                    chart: imgData,
                  })
                );
                navigate("/battery");
              });
            }
          }}
          px={{ base: 8, md: 16 }}
          py={{ base: 4, md: 8 }}
        >
          Suivant
        </PrimaryButton>
      </Box>
    </Box>
  );
};
