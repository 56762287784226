/* eslint-disable @typescript-eslint/no-explicit-any */
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(...registerables);
Chart.register(annotationPlugin);
interface DoughnutGraphProps {
  backgroundColor?: Array<string>;
  labels?: Array<string>;
  data?: Array<number>;
  formatter?: (value: number, context: any) => any;
}
export interface DoghnutData {
  backgroundColor: string[];
  labels: string[];
  data: number[] | undefined;
}
export const DoughnutGraph: React.FC<DoughnutGraphProps> = ({
  backgroundColor = [],
  labels = [],
  data: dataGrpah = [],
  formatter,
}) => {
  const plugins: any = {
    tooltip: {
      enabled: true,
    },
    datalabels: {
      enabled: true,
    },
  };

  const options = {
    cutout: "0%",
    hoverOffset: 4,
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        // Permet d'éviter que les labels se chevauchent si les segments sont trop petits.
        display: "auto",
        formatter,
      },
    },
  };
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    setData({
      labels: labels,
      datasets: [
        {
          data: dataGrpah,
          backgroundColor: backgroundColor,
        },
      ],
    });
  }, [labels, dataGrpah]);

  return data ? (
    <Doughnut
      data={data}
      plugins={plugins}
      options={options}
      height={"full"}
      width={"full"}
    />
  ) : (
    <div>Chargement...</div>
  );
};
