import { Box, Heading, HeadingProps, Icon, Tooltip } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import React, { ReactNode } from "react";

interface PrimaryHeadingProps extends HeadingProps {
  variant?: "h1" | "h2"; // Permet de définir un style pour H1 ou H2
  description?: string | ReactNode;
}

export const PrimaryHeading: React.FC<PrimaryHeadingProps> = React.forwardRef(
  ({ variant = "h1", children, description, ...props }, ref) => {
    const size =
      variant === "h1"
        ? { base: "sm", md: "md", lg: "lg" }
        : { base: "sm", md: "sm", lg: "md" };
    const color = "gray.900";
    const fontWeight = "bold";

    return (
      <Box position="relative" w="fit-content" ml={10} my={4}>
        <Heading
          ref={ref}
          size={size}
          color={color}
          fontWeight={fontWeight}
          textAlign="left"
          {...props}
        >
          {children}
        </Heading>

        {description && (
          <Tooltip label={description} hasArrow>
            <Box position="absolute" top={0} right={-6}>
              <Icon
                as={InfoOutlineIcon}
                w={4}
                h={4}
                color="content.secondary.500"
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  }
);
