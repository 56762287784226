// src/components/RadioGroupField.tsx
import {
  Box,
  Flex,
  useRadioGroup,
  VStack,
  Text,
  ResponsiveValue,
} from "@chakra-ui/react";
import React from "react";
import { RadioCard } from "./RadioCard";
import { InstallSizeData, OptiSizeData } from "../services/calculatorService";

interface Option {
  value: string | number;
  viewValue: string;
  description?: string | React.ReactNode;
  install?: keyof OptiSizeData;
}

interface RadioGroupFieldProps {
  name: string;
  options: Option[];
  defaultValue: string;
  setValue: (name: string, value: string) => void;
  size?: "sm" | "md" | "lg"; // Custom text size
  gap?: number; // Custom spacing
  boxW?: ResponsiveValue<string>; // Supports responsive sizes
  boxH?: ResponsiveValue<string>; // Supports responsive sizes
  renderExtra?: (option: Option) => React.ReactNode;
}

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  name,
  options,

  defaultValue,
  setValue,
  size = { base: "xl", md: "2xl", lg: "3xl" },
  gap = 4,
  boxW = { base: "100px", md: "150px", lg: "250px" },
  boxH = { base: "100px", md: "150px", lg: "200px" },
  renderExtra,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value) => setValue(name, value),
  });

  return (
    <Flex
      {...getRootProps()}
      flexDir="row"
      gap={{ base: gap, md: gap * 2 }}
      justifyContent={{ base: "start", md: "space-between" }}
      w="full"
      wrap="wrap"
    >
      {options.map((option) => {
        return (
          <VStack key={option.value} w="full" align="center" maxW="fit-content">
            <RadioCard
              {...getRadioProps({ value: option.value + "" })}
              description={option.description}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                h={boxH}
                w={boxW}
              >
                <Text fontSize={size}>{option.viewValue}</Text>
                {/* Si une fonction renderExtra est fournie, on l'appelle pour construire l'élément additionnel */}
                {renderExtra && renderExtra(option)}
              </Box>
            </RadioCard>
          </VStack>
        );
      })}
    </Flex>
  );
};
